import React from 'react';
import ReactDOM from 'react-dom';
import 'react-quill/dist/quill.bubble.css';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { createStore } from '@reduxjs/toolkit';

import 'antd/dist/antd.min.css';

import App from './App';
import history from './history';
import './index.css';
import { rootReducer } from './storage/RootStorage';

const store = createStore(rootReducer);

ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);
