import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { errorHandler, responseHandler, TIMEOUT } from './http-common';

import { apiAddress, monitorApiAddress } from '../config';

const requestHandler = (request: AxiosRequestConfig) => {
    return request;
};

const axiosMonitorAPI = axios.create({
    baseURL: monitorApiAddress,
    timeout: TIMEOUT,
    headers: {
        'X-Verbose': localStorage.getItem('verbose'),
    },
});

axiosMonitorAPI.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error),
);

axiosMonitorAPI.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error),
);

export default axiosMonitorAPI;
