import React from 'react';
import { connect } from 'react-redux';

import { Col, Row } from 'antd';

import { AboutUpdate } from './components/AboutUpdate';
import { DailyReportCarousel } from './components/DailyReportCarousel';

import { Company } from '../../models/Company';
import { User } from '../../models/User';
import { Zond } from '../../models/Zond';

interface Props {
    company: Company;
    user: User;
    zonds: Zond[];
}

const IndexPage = (props: Props) => {
    return (
        <>
            <Row gutter={8}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <AboutUpdate />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <DailyReportCarousel company={props.company} zonds={props.zonds} />
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        company: state.company.company,
        user: state.user.user,
        zonds: state.zonds.zonds,
    };
};

export default connect(mapStateToProps)(IndexPage);
