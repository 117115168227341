import React from 'react';

import { Col, Typography } from 'antd';

import { DigitalHelper } from '../../../models/DigitalHelper';

import moment from 'moment';

const { Title } = Typography;

interface Props {
    metrics: DigitalHelper;
}

export const SoilFrostPanel = (props: Props) => {
    const metrics = props.metrics;

    return (
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            {/*<OverlayTrigger overlay={<Tooltip id="tooltip2">Температура поверхности не выше 0</Tooltip>}>*/}
            <Title level={5}>Заморозки поверхности почвы</Title>
            {/*</OverlayTrigger>*/}
            <ul>
                <li>
                    Минимальная температура:
                    <b> {metrics.SoilFrost?.MinT0 ? `${Math.round(metrics.SoilFrost.MinT0)} °C` : '-'}</b>
                </li>
                {metrics.SoilFrost?.MinT0 && metrics.SoilFrost.MinT0 < 0 ? (
                    <>
                        <li>
                            Первый день заморозков:
                            <b>
                                {' '}
                                {metrics.SoilFrost?.FirstDate
                                    ? moment(metrics.SoilFrost.FirstDate * 1000).format('LL')
                                    : '-'}
                            </b>
                        </li>
                        <li>
                            Последний день заморозков:
                            <b>
                                {' '}
                                {metrics.SoilFrost?.LastDate
                                    ? moment(metrics.SoilFrost.LastDate * 1000).format('LL')
                                    : '-'}
                            </b>
                        </li>
                        <li>
                            Общая продолжительность:
                            <b> {metrics.SoilFrost?.Days} дней</b>
                        </li>
                    </>
                ) : (
                    ''
                )}
            </ul>
        </Col>
    );
};
