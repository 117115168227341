import React from 'react';

import { Typography } from 'antd';

const { Title, Paragraph, Link } = Typography;

export const HelpPage = () => {
    return (
        <>
            <Typography style={{ fontSize: '1.2em' }}>
                <Title level={3}>Нужна помощь?</Title>
                <Paragraph>
                    Многие вопросы, относящиеся к эксплуатации платформы, рассмотрены в&nbsp;
                    <Link href="https://agropogoda.com/static/files/manual.docx">данном документе</Link>.
                </Paragraph>
                <Paragraph>
                    По прочим вопросам касательно сервиса можно обращаться по номеру <strong>+7-983-234-30-40 </strong>
                    (Дмитрий).
                </Paragraph>
            </Typography>
        </>
    );
};
