import React, { useState } from 'react';

import { Select } from 'antd';

import { LocationRegion } from '../models/LocationRegion';
import { User } from '../models/User';

const { Option } = Select;

interface Props {
    user: User;
    regions: LocationRegion[];
    handleChangeRegion: (r: number) => void;
}

export default function RegionSelect(props: Props) {
    const [search, setSearch] = useState('');

    return (
        <Select
            showSearch={true}
            onChange={(value) => value && props.handleChangeRegion(value)}
            placeholder="Выберите район"
            style={{ width: 348, maxWidth: '100%' }}
            size="large"
            onSearch={(value) => setSearch(value)}
        >
            {props.regions.map((region) => {
                return (
                    <Option value={region.id} key={region.id}>
                        {region.name}
                    </Option>
                );
            })}
        </Select>
    );
}
