import React from 'react';
import { connect } from 'react-redux';

import { YandexMap } from './components/YandexMap';

import { User } from '../../models/User';
import { Zond } from '../../models/Zond';

interface Props {
    zonds: Zond[];
    user: User;
}

const DevicesPage = (props: Props) => {
    const zonds = props.zonds;
    const user = props.user;

    return <YandexMap zonds={zonds} user={user} />;
};

const mapStateToProps = (state: any) => {
    return {
        zonds: state.zonds.zonds,
        user: state.user.user,
    };
};

export default connect(mapStateToProps)(DevicesPage);
