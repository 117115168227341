import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { errorHandler, responseHandler } from './http-common';

import { adminApiAddress } from '../config';

const TIMEOUT = 15000; // 15 seconds

const requestHandler = (request: AxiosRequestConfig) => {
    request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

    return request;
};

const adminAxiosAPI = axios.create({
    baseURL: adminApiAddress,
    timeout: TIMEOUT,
    headers: {
        'Content-Type': 'application/json',
    },
});

adminAxiosAPI.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error),
);

adminAxiosAPI.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error),
);

export default adminAxiosAPI;
