import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Col, Divider, Input, Row, Spin, message } from 'antd';

import { AirTransitionDatePanel } from './components/AirTransitionDatePanel';
import { GrowingSeasonDurationPanel } from './components/GrowingSeasonDurationPanel';
import { GrowingSeasonHeatSupplyGraph } from './components/GrowingSeasonHeatSupplyGraph';
import { GrowingSeasonHeatSupplyPanel } from './components/GrowingSeasonHeatSupplyPanel';
import { MeteoPostStatsPanel } from './components/MeteoPostStatsPanel';
import { SoilFrostPanel } from './components/SoilFrostPanel';
import { SoilWarmingDatePanel } from './components/SoilWarmingDatePanel';
import { TemperatureStatsPanel } from './components/TemperatureStatsPanel';
import { WindStatsPanel } from './components/WindStatsPanel';

import DeviceSelect from '../../components/DeviceSelect';
import TimeRange from '../../components/TimeRange';
import { monitorAPI } from '../../http/MonitorAPI';
import { DigitalHelper } from '../../models/DigitalHelper';
import { User } from '../../models/User';
import { Zond } from '../../models/Zond';

interface Props {
    zonds: Zond[];
    user: User;
}

const DigitalHelperPage = (props: Props) => {
    let [selectedZond, setSelectedZond] = useState('');
    let [startDate, setStartDate] = useState<number | null>(null);
    let [endDate, setEndDate] = useState<number | null>(null);
    let [loaderOn, setLoaderOn] = useState(false);
    let [metrics, setMetrics] = useState<DigitalHelper | null>(null);

    const fetchMetrics = useCallback(async () => {
        if (startDate && endDate && selectedZond) {
            setLoaderOn(true);

            await monitorAPI
                .GetDigitalHelperMetrics(selectedZond, startDate, endDate)
                .then((res) => {
                    setMetrics(res);
                })
                .catch((_) => {
                    setMetrics(null);
                    message.error('Произошла ошибка при запросе данных, повторите попытку позже');
                });

            setLoaderOn(false);
        }
    }, [endDate, selectedZond, startDate]);

    const handleChangeZond = (z: string) => {
        setSelectedZond(z);
    };

    useEffect(() => {
        fetchMetrics().then(() => console.log(selectedZond, startDate, endDate));
    }, [fetchMetrics, startDate, endDate, selectedZond]);

    return (
        <>
            <Row>
                <Col span={24}>
                    <Input.Group compact>
                        <DeviceSelect user={props.user} zonds={props.zonds} handleChangeZond={handleChangeZond} />
                        <TimeRange
                            size="large"
                            onChange={(dates) => {
                                // @ts-ignore
                                setStartDate(dates[0].unix());
                                // @ts-ignore
                                setEndDate(dates[1].unix());
                            }}
                        />
                    </Input.Group>
                </Col>
            </Row>
            <Divider />
            {loaderOn && <Spin size="large" />}
            {metrics && (
                <Row gutter={40}>
                    <GrowingSeasonDurationPanel metrics={metrics} />
                    <GrowingSeasonHeatSupplyPanel metrics={metrics} />
                    <GrowingSeasonHeatSupplyGraph metrics={metrics} />
                    <AirTransitionDatePanel metrics={metrics} />
                    <TemperatureStatsPanel metrics={metrics} />
                    {metrics.SoilWarmingDate ? <SoilWarmingDatePanel metrics={metrics} /> : ''}
                    {metrics.SoilFrost ? <SoilFrostPanel metrics={metrics} /> : ''}
                    {metrics.FARStats && metrics.RainfallStats ? <MeteoPostStatsPanel metrics={metrics} /> : ''}
                    {metrics.WindStats ? <WindStatsPanel metrics={metrics} /> : ''}
                </Row>
            )}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.user,
        zonds: state.zonds.zonds,
    };
};

export default connect(mapStateToProps)(DigitalHelperPage);
