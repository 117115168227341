import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Input, Col, Row, Divider, message, Typography, Table, Spin } from 'antd';

import CompanySelect from '../../components/CompanySelect';
import RegionSelect from '../../components/RegionSelect';
import TimePeriod from '../../components/TimePeriod';
import { monitoringTimeDays } from '../../helpers/constants';
import { api } from '../../http/API';
import { monitorAPI } from '../../http/MonitorAPI';
import { AvgStats } from '../../models/AvgStats';
import { Company } from '../../models/Company';
import { DigitalHelper } from '../../models/DigitalHelper';
import { LocationRegion } from '../../models/LocationRegion';
import { User } from '../../models/User';

import moment from 'moment';

const { Title } = Typography;

interface Props extends RouteComponentProps<{ id: string }> {
    user: User;
}

const CompanyBrief = (props: Props) => {
    const user: User = props.user;

    let [companies, setCompanies] = useState<Company[]>([]);
    let [regions, setRegions] = useState<LocationRegion[]>([]);

    let [selectedRegion, setSelectedRegion] = useState(0);
    let [selectedCompany, setSelectedCompany] = useState('');
    let [selectedTime, setSelectedTime] = useState<number>(-1); // day

    let [loaderOn, setLoaderOn] = useState<boolean>(false);

    let [digitalHelperStats, setDigitalHelperStats] = useState<DigitalHelper>();
    let [avgStats, setAvgStats] = useState<AvgStats[]>([]);

    const getWeeklyBriefData = () => {
        console.log(digitalHelperStats);
        if (digitalHelperStats) {
            let data = [];
            if (digitalHelperStats.PeakTemperature.MaxT) {
                data.push({
                    title: 'Макс. температура воздуха',
                    value: digitalHelperStats.PeakTemperature.MaxT + '℃',
                });
            }
            if (digitalHelperStats.PeakTemperature.MinT) {
                data.push({
                    title: 'Мин. температура воздуха',
                    value: digitalHelperStats.PeakTemperature.MinT + '℃',
                });
            }
            if (digitalHelperStats.PeakHumidity.MaxHm) {
                data.push({
                    title: 'Макс. влажность воздуха',
                    value: digitalHelperStats.PeakHumidity.MaxHm + '%',
                });
            }
            if (digitalHelperStats.PeakHumidity.MinHm) {
                data.push({
                    title: 'Мин. влажность воздуха',
                    value: digitalHelperStats.PeakHumidity.MinHm + '%',
                });
            }
            if (digitalHelperStats.FARStats?.IntegralSum) {
                data.push({
                    title: 'Сумма ФАР',
                    value: Math.round(digitalHelperStats.FARStats.IntegralSum / 1000) + ' кВт/м²',
                });
            }
            if (digitalHelperStats.RainfallStats?.Sum) {
                data.push({
                    title: 'Сумма осадков',
                    value: Math.round(digitalHelperStats.RainfallStats?.Sum) + ' мм',
                });
            }
            if (digitalHelperStats.WindStats?.WindDirection !== 'не определено') {
                data.push({
                    title: 'Ветер',
                    value: digitalHelperStats.WindStats?.WindDirection,
                });
            }
            if (digitalHelperStats.WindStats?.MaxWindGusts) {
                data.push({
                    title: 'Порывы до',
                    value: Math.round(digitalHelperStats.WindStats?.MaxWindGusts) + ' м/с',
                });
            }
            if (digitalHelperStats.TemperatureStats.Count) {
                data.push({
                    title: 'Кол-во измерений',
                    value: digitalHelperStats.TemperatureStats.Count,
                });
            }
            return data;
        }
    };

    const getGroundTempData = () => {
        if (avgStats.length > 0) {
            let data = [];
            for (let i = 0; i < selectedTime; i++)
                data.push({
                    date: avgStats[i].date ? avgStats[i].date : null,
                    zero_sm: avgStats[i].t0 ? Math.round(avgStats[i].t0) + '℃' : 'Нет данных',
                    five_sm: avgStats[i].t1 ? Math.round(avgStats[i].t1) + '℃' : 'Нет данных',
                    ten_sm: avgStats[i].t2 ? Math.round(avgStats[i].t2) + '℃' : 'Нет данных',
                });
            return data;
        }
    };

    const getGroundHumData = () => {
        if (avgStats.length > 0) {
            let data = [];
            for (let i = 0; i < selectedTime; i++)
                data.push({
                    date: avgStats[i].date ? avgStats[i].date : null,
                    ten_sm: avgStats[i].soil1 ? Math.round(avgStats[i].soil1) + '%' : 'Нет данных',
                    thirty_sm: avgStats[i].soil2 ? Math.round(avgStats[i].soil2) + '%' : 'Нет данных',
                    sixty_sm: avgStats[i].soil3 ? Math.round(avgStats[i].soil3) + '%' : 'Нет данных',
                });
            return data;
        }
    };

    const fetchCompanies = useCallback(async () => {
        await api
            .GetCompanies()
            .then(async (resp) => {
                if (resp) {
                    console.log(resp);
                    setCompanies(resp);
                }
            })
            .catch((error) => {
                console.log(error);
                message.warning('Нет данных');
            });
    }, []);

    const fetchRegions = useCallback(async () => {
        await api
            .LocationRegions()
            .then(async (resp) => {
                if (resp) {
                    console.log(resp);
                    setRegions(resp);
                }
            })
            .catch((error) => {
                console.log(error);
                message.warning('Нет данных');
            });
    }, []);

    const fetchStats = async () => {
        setLoaderOn(true);
        for (let c of companies)
            if (c.name === selectedCompany) {
                let end = Math.floor(new Date().valueOf() / 1000); // now
                let start = Math.floor(new Date(new Date().getTime() - 86400 * selectedTime * 1000).valueOf() / 1000);

                let data: AvgStats[] = [];
                setAvgStats(data);

                let zondsAmount = 0;

                for (let z of c.zonds) {
                    if (z.zond_type_id === 4) {
                        await monitorAPI
                            .GetDigitalHelperMetrics(z.number_in_db, start, end)
                            .then(async (resp) => {
                                if (resp) {
                                    setDigitalHelperStats(resp);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                message.warning('Нет данных');
                            });
                    }
                    if (z.zond_type_id)
                        if ([5, 6].includes(z.zond_type_id)) {
                            await monitorAPI
                                .GetMetricsWithFunctionRaw(z.number_in_db, start, end, 'avg')
                                .then(async (resp) => {
                                    if (resp) {
                                        console.log(z.zond_type_id);
                                        console.log(resp);
                                        zondsAmount++;
                                        if (data.length === 0)
                                            for (let i = 0; i < selectedTime; i++)
                                                data.push({
                                                    date: moment(Date.now() - i * 24 * 3600 * 1000).format('DD.MM'),
                                                    t0: resp[i].t0,
                                                    t1: resp[i].t1,
                                                    t2: resp[i].t2,
                                                    soil1: resp[i].soil1
                                                        ? (resp[i].soil1 * 100) / 4096
                                                        : resp[i].soil1c,
                                                    soil2: resp[i].soil2
                                                        ? (resp[i].soil2 * 100) / 4096
                                                        : resp[i].soil2c,
                                                    soil3: resp[i].soil3
                                                        ? (resp[i].soil3 * 100) / 4096
                                                        : resp[i].soil3c,
                                                });
                                        else
                                            for (let i = 0; i < selectedTime; i++) {
                                                data[i].t0 += resp[i].t0;
                                                data[i].t1 += resp[i].t1;
                                                data[i].t2 += resp[i].t2;
                                                data[i].soil1 += resp[i].soil1
                                                    ? (resp[i].soil1 * 100) / 4096
                                                    : resp[i].soil1c;
                                                data[i].soil2 += resp[i].soil2
                                                    ? (resp[i].soil2 * 100) / 4096
                                                    : resp[i].soil2c;
                                                data[i].soil3 += resp[i].soil3
                                                    ? (resp[i].soil3 * 100) / 4096
                                                    : resp[i].soil3c;
                                            }
                                        if (data.length > 0) {
                                            for (let i = 0; i < selectedTime; i++) {
                                                data[i].t0 ? (data[i].t0 /= zondsAmount) : console.log('no t0');
                                                data[i].t1 ? (data[i].t1 /= zondsAmount) : console.log('no t1');
                                                data[i].t2 ? (data[i].t2 /= zondsAmount) : console.log('no t2');
                                                data[i].soil1
                                                    ? (data[i].soil1 /= zondsAmount)
                                                    : console.log('no soil1');
                                                data[i].soil2
                                                    ? (data[i].soil2 /= zondsAmount)
                                                    : console.log('no soil2');
                                                data[i].soil3
                                                    ? (data[i].soil3 /= zondsAmount)
                                                    : console.log('no soil3');
                                            }
                                            setAvgStats(data);
                                        } else {
                                            setAvgStats([]);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    message.warning('Нет данных');
                                });
                        }
                }

                setLoaderOn(false);
                console.log(data);
            }
    };

    const handleChangeRegion = (r: number) => {
        setSelectedRegion(r);
    };

    const handleChangeCompany = (c: string) => {
        setSelectedCompany(c);
    };

    const handleChangeTime = (t: string) => {
        setSelectedTime(monitoringTimeDays[t]);
    };

    useEffect(() => {
        fetchCompanies().then(() => {});
        fetchRegions().then(() => {});
    }, [fetchCompanies, fetchRegions]);

    useEffect(() => {
        if (selectedTime > 0) fetchStats().then(() => {});
    }, [selectedCompany, selectedTime]);

    const weeklyBriefDataColumns = [
        {
            dataIndex: 'title',
            key: 'title',
        },
        {
            dataIndex: 'value',
            key: 'value',
        },
    ];

    const groundTempDataColumns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '0 см',
            dataIndex: 'zero_sm',
            key: 'zero_sm',
        },
        {
            title: '5 см',
            dataIndex: 'five_sm',
            key: 'five_sm',
        },
        {
            title: '10 см',
            dataIndex: 'ten_sm',
            key: 'ten_sm',
        },
    ];

    const groundHumDataColumns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '10 см',
            dataIndex: 'ten_sm',
            key: 'ten_sm',
        },
        {
            title: '30 см',
            dataIndex: 'thirty_sm',
            key: 'thirty_sm',
        },
        {
            title: '60 см',
            dataIndex: 'sixty_sm',
            key: 'sixty_sm',
        },
    ];

    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={16}>
                    <Input.Group compact>
                        <RegionSelect user={user} regions={regions} handleChangeRegion={handleChangeRegion} />
                        <CompanySelect
                            user={user}
                            companies={companies}
                            selectedRegion={selectedRegion}
                            handleChangeCompany={handleChangeCompany}
                        />
                        <TimePeriod
                            handleChangeTime={handleChangeTime}
                            useDates={selectedCompany === '' ? true : false}
                            monitoringTimePeriods={monitoringTimeDays}
                        />
                    </Input.Group>
                </Col>
            </Row>
            <Divider />

            <Title level={3}>
                {selectedTime > 0 && 'Cводка для ' + selectedCompany + ' за кол-во дней: ' + selectedTime}
            </Title>
            {loaderOn && <Spin size="large" />}
            {digitalHelperStats && (
                <Table
                    pagination={false}
                    size="small"
                    dataSource={getWeeklyBriefData()}
                    columns={weeklyBriefDataColumns}
                />
            )}

            {avgStats.length === selectedTime && (
                <>
                    <Divider />
                    <Title level={3}>Средняя температура грунта</Title>
                    <Table
                        pagination={false}
                        size="small"
                        dataSource={getGroundTempData()}
                        columns={groundTempDataColumns}
                    />
                    <Divider />
                    <Title level={3}>Средняя влажность грунта</Title>
                    <Table
                        pagination={false}
                        size="small"
                        dataSource={getGroundHumData()}
                        columns={groundHumDataColumns}
                    />
                </>
            )}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.user,
    };
};

export default connect(mapStateToProps)(CompanyBrief);
