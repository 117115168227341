import React, { useEffect, useState } from 'react';

import { message, Skeleton, Typography } from 'antd';

import { monitorAPI } from '../../../http/MonitorAPI';
import { DigitalHelper } from '../../../models/DigitalHelper';
import { Zond } from '../../../models/Zond';

import moment from 'moment';

const { Text, Title, Paragraph } = Typography;

interface Props {
    post: Zond;
}

export const CarouselItem = (props: Props) => {
    const [metrics, setMetrics] = useState<DigitalHelper | null>(null);

    useEffect(() => {
        const d = Math.round(Date.now() / 1000);
        monitorAPI
            .GetDigitalHelperMetrics(props.post.number_in_db, d - 86400, d)
            .then((resp) => {
                setMetrics(resp);
            })
            .catch((_) => message.warning('Не удалось получить суточную сводку'));
    }, [props.post.number_in_db]);

    return (
        <>
            {metrics ? (
                <>
                    <Title
                        level={5}
                        type="secondary"
                    >{`${props.post.zond_type.displayed_name} №${props.post.number}`}</Title>
                    <Paragraph>
                        Макс. темп. воздуха: <strong>{metrics.PeakTemperature.MaxT}°C</strong>&nbsp; (
                        {metrics.PeakTemperature.MaxDate
                            ? moment(metrics.PeakTemperature.MaxDate * 1000).format('LLL')
                            : '-'}
                        )
                        <br />
                        Мин. темп. воздуха: <strong>{metrics.PeakTemperature.MinT}°C</strong>&nbsp; (
                        {metrics.PeakTemperature.MinDate
                            ? moment(metrics.PeakTemperature.MinDate * 1000).format('LLL')
                            : '-'}
                        )
                        <br />
                        <br />
                        Макс. влажность: <strong>{metrics.PeakHumidity.MaxHm}%</strong>&nbsp; (
                        {metrics.PeakHumidity.MaxDate ? moment(metrics.PeakHumidity.MaxDate * 1000).format('LLL') : '-'}
                        )
                        <br />
                        Мин. влажность: <strong>{metrics.PeakHumidity.MinHm}%</strong>&nbsp; (
                        {metrics.PeakHumidity.MinDate ? moment(metrics.PeakHumidity.MinDate * 1000).format('LLL') : '-'}
                        )
                        <br />
                        <br />
                        Ветер: преимущественно <strong>{metrics.WindStats?.WindDirection}</strong>
                        <br />
                        Порывы ветра: <strong>до {metrics.WindStats?.MaxWindGusts?.toFixed(1)} м/c</strong>
                        <br />
                        <br />
                        Сумма осадков: <strong>{metrics.RainfallStats?.Sum?.toFixed(1)} мм</strong>
                        <br />
                        Сумма ФАР:{' '}
                        <strong>
                            {metrics.FARStats?.IntegralSum && Math.round(metrics.FARStats?.IntegralSum / 1000)} кВт/м²
                        </strong>
                        <br />
                        <br />
                        <Text type="secondary">Кол-во измерений: {metrics.TemperatureStats.Count}</Text>
                    </Paragraph>
                </>
            ) : (
                <>
                    <Skeleton />
                    <Skeleton />
                </>
            )}
        </>
    );
};
