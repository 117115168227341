import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Modal, Alert, Button, Typography, Divider, DatePicker, message } from 'antd';

import DeviceSelect from '../../components/DeviceSelect';
import { monitorAPI } from '../../http/MonitorAPI';
import { AgroModel, AgroModelOutcome } from '../../models/AgroModel';
import { User } from '../../models/User';
import { Zond } from '../../models/Zond';

const { Title, Text } = Typography;

interface Props {
    agroModel: AgroModel;
    zonds: Zond[];
    user: User;
}

const AgroModelTab = (props: Props) => {
    const am = props.agroModel;

    const [agroModelOutcome, setAgroModelOutcome] = useState<AgroModelOutcome | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showVerboseOutput, setShowVerboseOutput] = useState(false);

    let [selectedZond, setSelectedZond] = useState('');
    let [startDate, setStartDate] = useState('');

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    useEffect(() => {
        if (selectedZond)
            monitorAPI
                .GetAgroModelOutcome(am.link, selectedZond, startDate)
                .then((res) => {
                    setAgroModelOutcome(res);
                })
                .catch(async (_) => {
                    await message.error('Не удалось получить результат работы модели');
                });
    }, [am.link, selectedZond, startDate]);

    const handleChangeZond = (z: string) => {
        setSelectedZond(z);
    };

    return (
        <>
            <Title level={3}>{am.name}</Title>
            <Text type="secondary">{am.short_description}</Text>
            <Button type="link" onClick={() => handleShowModal()}>
                Подробное описание
            </Button>
            <br />
            <br />
            <DeviceSelect user={props.user} zonds={props.zonds} handleChangeZond={handleChangeZond} />
            {am.has_start_date && (
                <DatePicker
                    size="large"
                    onChange={(date) => {
                        date && setStartDate(date.unix().toString());
                    }}
                    style={{ width: 350, maxWidth: '100%' }}
                    placeholder="Начальная дата"
                />
            )}
            <Divider />
            {agroModelOutcome && (
                <>
                    <Alert
                        style={styles.longTextStyle}
                        type={agroModelOutcome.Variant}
                        message={agroModelOutcome.Text}
                    />
                    <br />
                    <Text style={styles.longTextStyle}>{agroModelOutcome.Details}</Text>
                    <Button type="dashed" onClick={() => setShowVerboseOutput(!showVerboseOutput)}>
                        Полный лог
                    </Button>
                    {showVerboseOutput && (
                        <pre>
                            <br />
                            {agroModelOutcome.VerboseOutput}
                        </pre>
                    )}
                </>
            )}

            <Modal
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={() => setShowModal(false)}
                title="Описание модели"
                visible={showModal}
                onOk={handleCloseModal}
            >
                <p style={styles.longTextStyle}>{am.long_description}</p>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.user,
        zonds: state.zonds.zonds,
    };
};

export default connect(mapStateToProps)(AgroModelTab);

const styles = {
    longTextStyle: {
        whiteSpace: 'pre-line' as 'pre-line',
        width: 700,
        maxWidth: '100%',
    },
};
