import axios from 'axios';

import axiosMonitorAPI from './axiosMonitorAPI';

import { AgroModelOutcome } from '../models/AgroModel';
import { AvgStats } from '../models/AvgStats';
import { DigitalHelper } from '../models/DigitalHelper';
import { Graph } from '../models/Graph';
import { WeatherModel } from '../models/WeatherModel';

export class MonitorAPI {
    GetLastMetrics(zondNumberInDB: string): Promise<Graph[]> {
        return new Promise((resolve, reject) => {
            axiosMonitorAPI
                .get(`/get_last_metrics/${zondNumberInDB}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetMetrics(zondNumberInDB: string, startDate: number, endDate: number): Promise<Graph[]> {
        return new Promise((resolve, reject) => {
            axiosMonitorAPI
                .get(`/get_metrics?number_in_db=${zondNumberInDB}&start_date=${startDate}&end_date=${endDate}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetMetricsWithFunction(
        zondNumberInDB: string,
        startDate: number,
        endDate: number,
        func: string,
        tz: string,
    ): Promise<Graph[]> {
        return new Promise((resolve, reject) => {
            axiosMonitorAPI
                .get(
                    `/get_metrics/${func}?number_in_db=${zondNumberInDB}&start_date=${startDate}&end_date=${endDate}&tz=${tz}`,
                )
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetMetricsWithFunctionRaw(zondNumberInDB: string, startDate: number, endDate: number, func: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axiosMonitorAPI
                .get(
                    `/get_metrics_with_function/raw/${func}?number_in_db=${zondNumberInDB}&start_date=${startDate}&end_date=${endDate}`,
                )
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetDigitalHelperMetrics(zondNumberInDB: string, startDate: number, endDate: number): Promise<DigitalHelper> {
        return new Promise((resolve, reject) => {
            axiosMonitorAPI
                .get(`/digital_helper?number_in_db=${zondNumberInDB}&start_date=${startDate}&end_date=${endDate}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetWeather(zondNumberInDB: string): Promise<WeatherModel> {
        return new Promise((resolve, reject) => {
            axiosMonitorAPI
                .get(`/get_weather/${zondNumberInDB}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetAgroModelOutcome(link: string, selectedZond: string, startDate: string): Promise<AgroModelOutcome> {
        let URL = startDate ? `${link}?zond=${selectedZond}&start=${startDate}` : `${link}?zond=${selectedZond}`;
        return new Promise((resolve, reject) => {
            axios
                .get(URL)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}

export const monitorAPI = new MonitorAPI();
