import React from 'react';

import { Bar, BarChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts';

import { Col, Typography } from 'antd';

import { DigitalHelper } from '../../../models/DigitalHelper';

const { Title } = Typography;

interface Props {
    metrics: DigitalHelper;
}

export const MeteoPostStatsPanel = (props: Props) => {
    const metrics = props.metrics;

    // @ts-ignore
    let data = [];
    if (metrics.FARStats && metrics.FARStats.DailyData) {
        for (let i = 0; i < metrics.FARStats.DailyData.length; i++) {
            data.push({
                'Суточный ФАР': Math.round(metrics.FARStats.DailyData[i]),
            });
        }
    }

    return (
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <Title level={5}>Прочие показатели</Title>
            <ul>
                <li>
                    Сумма осадков:
                    <b> {metrics.RainfallStats?.Sum ? `${metrics.RainfallStats?.Sum.toFixed(2)} мм` : '-'}</b>
                </li>
                <li>
                    Сумма ФАР:
                    <b>
                        {' '}
                        {metrics.FARStats?.IntegralSum
                            ? `${Math.round(metrics.FARStats?.IntegralSum / 1000)} кВт/м²`
                            : '-'}
                    </b>
                </li>
            </ul>
            <ResponsiveContainer width="100%" height={100}>
                <BarChart
                    height={1000}
                    data={data}
                    margin={{
                        bottom: 20,
                    }}
                >
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="Суточный ФАР" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </Col>
    );
};
