import axios from 'axios';

import adminAxiosAPI from './adminAxiosAPI';
import axiosAPI from './axiosAPI';

import { apiAddress } from '../config';
import { AgroModel } from '../models/AgroModel';
import { Company } from '../models/Company';
import { Field } from '../models/Field';
import { LocationRegion } from '../models/LocationRegion';
import { News } from '../models/News';
import { User } from '../models/User';
import { WikiPage, WikiSection } from '../models/Wiki';
import { Zond } from '../models/Zond';
import { ZondNotification } from '../models/ZondNotification';

export class ResponseOK {
    status: boolean = true;
    message: string = '';
}

export class API {
    LoginUser(username: string, password: string): Promise<{ token: string; is_admin: boolean }> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${apiAddress}/login`, {
                    username: username,
                    password: password,
                })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetUser(): Promise<User> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/user`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetCompany(): Promise<Company> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/company`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetCompanies(): Promise<Company[]> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/all_companies`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    LocationRegions(): Promise<LocationRegion[]> {
        return new Promise((resolve, reject) => {
            adminAxiosAPI
                .get(`/location_region?_start=0&_end=100`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetZonds(): Promise<Zond[]> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/zonds`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetFieldOfCompany(): Promise<Field> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/field_of_company`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetLastNews(): Promise<News[]> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/last_news`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetWikiSection(id = 1): Promise<WikiSection> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/wiki_section/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetWikiPage(id = 0): Promise<WikiPage> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/wiki_page/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetAgroModels(): Promise<AgroModel[]> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/agro_models`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    GetZondNotifications(start = 0, end = 25): Promise<ZondNotification[]> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .get(`/zond_notifications?_start=${start}&_end=${end}`)
                .then((response) => {
                    let res = [];
                    for (let n of response.data) {
                        n['total_count'] = parseInt(response.headers['x-total-count']);
                        res.push(n);
                    }
                    resolve(res);
                })
                .catch((error) => reject(error));
        });
    }

    CheckZondNotification(id: number): Promise<ResponseOK> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .post(`/check_notification/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    EditUser(email: string, name?: string, surname?: string, patronymic?: string, phone?: string): Promise<ResponseOK> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .post(`/edit_user`, {
                    name: name,
                    surname: surname,
                    patronymic: patronymic,
                    email: email,
                    phone_number: phone,
                })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    EditCompany(name: string, description?: string, address?: string, phone?: string): Promise<ResponseOK> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .post(`/edit_company`, {
                    name: name,
                    description: description,
                    phone: phone,
                    address: address,
                })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    ChangePassword(password: string, newPassword: string): Promise<ResponseOK> {
        return new Promise((resolve, reject) => {
            axiosAPI
                .post(`/change_password`, {
                    password: password,
                    new_password: newPassword,
                })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}

export const api = new API();
