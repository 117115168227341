import { createStore } from 'redux';

import { combineReducers } from '@reduxjs/toolkit';

import { CompanyStorage } from './CompanyStorage';
import { UserStorage } from './UserStorage';
import { ZondsStorage } from './ZondsStorage';

export const rootReducer = combineReducers({
    company: CompanyStorage.reducer,
    user: UserStorage.reducer,
    zonds: ZondsStorage.reducer,
});

export const store = createStore(rootReducer);
