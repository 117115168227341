import React from 'react';

import { Col, Typography } from 'antd';

import { DigitalHelper } from '../../../models/DigitalHelper';

import moment from 'moment';

const { Title } = Typography;

interface Props {
    metrics: DigitalHelper;
}

export const AirTransitionDatePanel = (props: Props) => {
    const metrics = props.metrics;
    const atsd = metrics['AirTransitionSpringDate'];
    const atad = metrics['AirTransitionAutumnDate'];

    return (
        <>
            {atsd['T0'] || atsd['T5'] || atsd['T10'] ? (
                <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Title level={5}>Весенний переход среднесуточной температуры</Title>
                    <ul>
                        <li>
                            через 10°C:
                            <b> {atsd['T10'] ? moment(atsd['T10'] * 1000).format('LL') : '-'}</b>
                        </li>
                        <li>
                            через 5°C:
                            <b> {atsd['T5'] ? moment(atsd['T5'] * 1000).format('LL') : '-'}</b>
                        </li>
                        <li>
                            через 0°C:
                            <b> {atsd['T0'] ? moment(atsd['T0'] * 1000).format('LL') : '-'}</b>
                        </li>
                    </ul>
                </Col>
            ) : (
                ''
            )}
            {atad['T0'] || atad['T5'] || atad['T10'] ? (
                <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Title level={5}>Осенний переход среднесуточной температуры</Title>
                    <ul>
                        <li>
                            через 10°C:
                            <b> {atad['T10'] ? moment(atad['T10'] * 1000).format('LL') : '-'}</b>
                        </li>
                        <li>
                            через 5°C:
                            <b> {atad['T5'] ? moment(atad['T5'] * 1000).format('LL') : '-'}</b>
                        </li>
                        <li>
                            через 0°C:
                            <b> {atad['T0'] ? moment(atad['T0'] * 1000).format('LL') : '-'}</b>
                        </li>
                    </ul>
                </Col>
            ) : (
                ''
            )}
        </>
    );
};
