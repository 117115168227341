import React from 'react';

import { Col, Typography } from 'antd';

import { DigitalHelper } from '../../../models/DigitalHelper';

const { Title } = Typography;

interface Props {
    metrics: DigitalHelper;
}

export const WindStatsPanel = (props: Props) => {
    const metrics = props.metrics;

    return (
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <Title level={5}>Показатели силы ветра</Title>
            <ul>
                <li>
                    Преимущественное направление:
                    <b> {metrics.WindStats?.WindDirection ? `${metrics.WindStats.WindDirection}` : '-'}</b>
                </li>
                <li>
                    Максимальные порывы:
                    <b>
                        {' '}
                        {metrics.WindStats?.MaxWindGusts ? `до ${Math.floor(metrics.WindStats.MaxWindGusts)} м/c` : '-'}
                    </b>
                </li>
            </ul>
        </Col>
    );
};
