import React, { useEffect, useState } from 'react';

import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceArea,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { Button, Switch } from 'antd';

import { getDataMax, getDataMin, preprocessDataForLineChart } from '../../../helpers/RechartsPreprocess';
import { materialColorPalette } from '../../../helpers/constants';
import { Graph } from '../../../models/Graph';

import moment from 'moment';

interface Props {
    graph: Graph;
    isOldZond: boolean;
}

export const AirPressureLineChart = (props: Props) => {
    let [graph, setGraph] = useState(props.graph);

    let [multiplier, setMultiplier] = useState(1);
    let [data, setData] = useState(preprocessDataForLineChart(graph['Values'], graph['Legends'], multiplier));
    let [left, setLeft] = useState('dataMin');
    let [right, setRight] = useState('dataMax');
    let [refAreaLeft, setRefAreaLeft] = useState('');
    let [refAreaRight, setRefAreaRight] = useState('');
    let [zoomed, setZoomed] = useState(false);

    let o: { [key: string]: number } = {};
    for (let l of graph.Legends) {
        o[l] = 1;
    }

    let [opacity, setOpacity] = useState(o);

    const handleMouseEnter = (e: any) => {
        let dataKey: string = e['dataKey'];
        let newOpacity: { [key: string]: number } = {};

        for (let l of graph.Legends) {
            newOpacity[l] = 0.1;
        }

        newOpacity[dataKey] = 1;
        setOpacity(newOpacity);
    };

    const handleMouseLeave = () => {
        let newOpacity: { [key: string]: number } = {};

        for (let l of graph.Legends) {
            newOpacity[l] = 1;
        }

        setOpacity(newOpacity);
    };

    const sliceData = (leftDate: number, rightDate: number): (number | null)[][] => {
        let result: (number | null)[][] = [];
        for (let g of graph.Values) {
            if (g[0] && g[0] > leftDate && g[0] < rightDate) {
                result.push(g);
            }
        }

        return result;
    };

    const zoom = () => {
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setRefAreaLeft('');
            setRefAreaRight('');
            return;
        }

        let ld = moment(refAreaLeft, 'LLL', 'ru').unix();
        let rd = moment(refAreaRight, 'LLL', 'ru').unix();

        if (ld > rd) {
            [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
            [ld, rd] = [rd, ld];
        }

        let g = Object.assign({}, graph);
        g.Values = sliceData(ld, rd);

        const data = preprocessDataForLineChart(g.Values, g.Legends);

        setGraph(g);
        setData(data);
        setRefAreaLeft('');
        setRefAreaRight('');
        setLeft(refAreaLeft);
        setRight(refAreaRight);
        setZoomed(true);
    };

    const zoomOut = () => {
        setGraph(props.graph);
        setData(preprocessDataForLineChart(props.graph.Values, props.graph.Legends));
        setRefAreaLeft('');
        setRefAreaRight('');
        setLeft('dataMin');
        setRight('dataMax');
        setZoomed(false);
    };

    useEffect(() => {
        setGraph(props.graph);
        setData(preprocessDataForLineChart(props.graph['Values'], props.graph['Legends'], multiplier));
    }, [props.graph, multiplier]);

    return (
        <>
            {zoomed ? <Button onClick={() => zoomOut()}>отменить приближение</Button> : ''}
            <Switch onChange={(e) => setMultiplier(e ? 0.00750063755419211 : 1)} />
            <span style={{ paddingLeft: 8 }}>Отобразить в мм.рт.ст.</span>
            <ResponsiveContainer width="98%" height={400}>
                <LineChart
                    onMouseDown={(e: any) => {
                        setRefAreaLeft(e.activeLabel);
                    }}
                    onMouseMove={(e: any) => {
                        refAreaLeft && setRefAreaRight(e.activeLabel);
                    }}
                    onMouseUp={() => zoom()}
                    data={data}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis allowDataOverflow dataKey={graph['Legends'][0]} domain={[left, right]} />
                    <YAxis
                        allowDataOverflow
                        type="number"
                        yAxisId="1"
                        domain={[getDataMin(graph['Values'], multiplier), getDataMax(graph['Values'], multiplier)]}
                    />
                    <Tooltip filterNull={true} />
                    <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                    {graph['Legends'].map((legend, i) => {
                        if (i >= 1) {
                            if (legend.startsWith('(прогноз)')) {
                                const offset = Math.floor(graph.Legends.length / 2);
                                return (
                                    <Line
                                        connectNulls
                                        key={i}
                                        yAxisId={'1'}
                                        type="monotone"
                                        dataKey={legend}
                                        stroke={materialColorPalette[i - offset]}
                                        strokeWidth={2}
                                        strokeDasharray="5 5"
                                    />
                                );
                            } else
                                return (
                                    <Line
                                        connectNulls
                                        key={i}
                                        yAxisId={'1'}
                                        type="natural"
                                        dataKey={legend}
                                        strokeWidth={2}
                                        strokeOpacity={opacity[legend]}
                                        stroke={materialColorPalette[i]}
                                    />
                                );
                        } else return <span key={i} />;
                    })}
                    {refAreaLeft && refAreaRight ? (
                        <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                    ) : null}
                </LineChart>
            </ResponsiveContainer>
        </>
    );
};
