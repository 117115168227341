// python-like
// @ts-ignore
export const zip = (...rows) => [...rows[0]].map((_, c) => rows.map((row) => row[c]));

// calculating average with existing values
export const arrayAverageByKey = (array: any[], key: string): number => {
    let sum = 0;
    let haveValues = 0;
    for (let elem of array) {
        if (elem[key]) {
            sum += elem[key];
            haveValues++;
        }
    }
    return sum / haveValues;
};

export function sortArrayByKey(array: any[], key: string) {
    return array.sort(function (a, b) {
        let x = a[key];
        let y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
}
