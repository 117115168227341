import { createSlice } from '@reduxjs/toolkit';

import { User } from '../models/User';

export const UserStorage = createSlice({
    name: 'user',
    initialState: {
        user: User,
    },
    reducers: {
        saveUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const { saveUser } = UserStorage.actions;

interface RootState {
    user: User;
}

export const selectUser = (state: RootState) => state.user;

export default UserStorage.reducer;
