import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Alert, Empty, message, Spin, Typography, Pagination, Divider } from 'antd';

import { api } from '../../http/API';
import { User } from '../../models/User';
import { ZondNotification } from '../../models/ZondNotification';

import moment from 'moment';

const { Title } = Typography;

interface Props {
    user: User;
}

const N = 100;

const NotificationsPage = (props: Props) => {
    const user = props.user;

    const [zondNotifications, setZondNotifications] = useState<ZondNotification[]>([]);
    const [loaderOn, setLoaderOn] = useState(true);
    const [counter, setCounter] = useState(0);
    const [totalCount, setTotalCount] = useState(N);
    const [end, setEnd] = useState(N);
    const [start, setStart] = useState(0);

    useEffect(() => {
        api.GetZondNotifications(start, end)
            .then((resp) => {
                setLoaderOn(false);
                setZondNotifications(resp);

                let c = 0;
                for (let zn of resp) {
                    if (!zn.is_checked) c++;
                }

                setCounter(c);
                if (resp.length > 0) setTotalCount(resp[0].total_count);
            })
            .catch((_) => {
                setLoaderOn(false);
                message.error('Произошла ошибка при загрузке оповещений');
            });
    }, [start, end]);

    return (
        <>
            <Title level={3}>Список последних оповещений</Title>
            {loaderOn && <Spin size="large" />}
            {!loaderOn && counter === 0 && !user.is_admin && <Empty />}
            {zondNotifications.map((zn, i) => {
                if (user.is_admin || !zn.notification.admin_only) {
                    return (
                        <Alert
                            key={i}
                            message={`${zn.notification.title} (${moment(zn.created_at).format('LLL')})`}
                            description={zn.additional_info}
                            type={zn.notification.notification_type.alert}
                            style={{ opacity: zn.is_checked ? 0.3 : 1 }}
                            showIcon
                            closable={!zn.is_checked}
                            afterClose={() => {
                                api.CheckZondNotification(zn.id).then(() => console.log('checked'));
                                setCounter(counter - 1);
                            }}
                        />
                    );
                } else return '';
            })}
            <Divider />
            <Pagination
                responsive={true}
                defaultCurrent={1}
                total={totalCount}
                onChange={(page, pageSize) => {
                    if (!pageSize) pageSize = N;
                    setStart(pageSize * (page - 1));
                    setEnd(pageSize * page);
                }}
            />
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.user,
    };
};

export default connect(mapStateToProps)(NotificationsPage);
