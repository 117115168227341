import { zip } from './functions';

import moment from 'moment';

export function preprocessDataForLineChart(values: (number | null)[][], legends: string[], multiplier = 1): any[] {
    let result = [];
    const step = Math.round(values.length / 120 + 1);

    for (let i = 0; i < values.length; i += step) {
        let v = values[i];
        let row: any;
        row = {};

        const z = zip(v, legends);
        for (let j = 0; j < z.length; j++) {
            let r = z[j];

            if (r[1] === 'Осадки (мм)' || r[1] === 'Заморозок (измерений)') {
                for (let k = i - step + 1; k < i; k++) {
                    if (values[k]) r[0] += values[k][1];
                }
            }

            if (j === 0) {
                row[r[1]] = moment(r[0] * 1000).format('LLL');
            } else {
                row[r[1]] = (parseFloat(r[0]) * multiplier).toFixed(1);
                if (isNaN(row[r[1]])) row[r[1]] = null;
            }
        }
        result.push(row);
    }

    return result;
}

// роза ветров
export function preprocessDataForRadarChart(values: (number | null)[][]) {
    let result = [
        { Direction: 'С', Amount: 0, LastDirection: 0 },
        { Direction: 'С-В', Amount: 0, LastDirection: 0 },
        { Direction: 'В', Amount: 0, LastDirection: 0 },
        { Direction: 'Ю-В', Amount: 0, LastDirection: 0 },
        { Direction: 'Ю', Amount: 0, LastDirection: 0 },
        { Direction: 'Ю-З', Amount: 0, LastDirection: 0 },
        { Direction: 'З', Amount: 0, LastDirection: 0 },
        { Direction: 'С-З', Amount: 0, LastDirection: 0 },
    ];

    let v = null;
    for (let line of values) {
        v = line[1];

        if (v) {
            if (v > 337.5) result[0].Amount += 1;
            else if (v > 292.5) result[7].Amount += 1;
            else if (v > 247.5) result[6].Amount += 1;
            else if (v > 202.5) result[5].Amount += 1;
            else if (v > 157.5) result[4].Amount += 1;
            else if (v > 112.5) result[3].Amount += 1;
            else if (v > 67.5) result[2].Amount += 1;
            else if (v > 22.5) result[1].Amount += 1;
            else if (v > 0) result[0].Amount += 1;
        }
    }

    return setLastDirection(v, result);
}

function setLastDirection(v: number | null, result: any[]): any[] {
    let s = Math.max.apply(
        Math,
        result.map(function (o) {
            return o.Amount;
        }),
    );

    if (v) {
        if (v > 337.5) result[0]['LastDirection'] = s;
        else if (v > 292.5) result[7]['LastDirection'] = s;
        else if (v > 247.5) result[6]['LastDirection'] = s;
        else if (v > 202.5) result[5]['LastDirection'] = s;
        else if (v > 157.5) result[4]['LastDirection'] = s;
        else if (v > 112.5) result[3]['LastDirection'] = s;
        else if (v > 67.5) result[2]['LastDirection'] = s;
        else if (v > 22.5) result[1]['LastDirection'] = s;
        else if (v > 0) result[0]['LastDirection'] = s;
    }

    return result;
}

export function getDataMin(values: (number | null)[][], multiplier = 1): number {
    let result = 10000000;
    for (let v of values) {
        let m = Math.min.apply(Math, removeNulls(v.slice(1, v.length)));
        if (m < result) result = m;
    }

    result = Math.floor(result * multiplier);
    return result === 1 ? 0 : result;
}

export function getDataMax(values: (number | null)[][], multiplier = 1): number {
    let result = -10000000;
    for (let v of values) {
        let m = Math.max.apply(Math, removeNulls(v.slice(1, v.length)));
        if (m > result) result = m;
    }
    return Math.ceil(result * multiplier);
}

function removeNulls(array: (number | null)[]): number[] {
    let newArray = [];
    for (let e of array) {
        if (e) newArray.push(e);
    }

    return newArray;
}
