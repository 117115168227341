import { AxiosResponse } from 'axios';

import { message } from 'antd';

import history from '../history';

export const TIMEOUT = 15000; // 15 seconds

export const errorHandler = (error: any) => {
    if (error.toString() === `Error: timeout of ${TIMEOUT}ms exceeded`) {
        message.error('Превышено время ожидания запроса');
    } else if (error.response) {
        let m = error.response.data['message'];
        console.log(m); // for developer

        let status = error.response.status;
        if (status === 401) {
            history.push('/login');
        } else if (status >= 500) {
            message.error('Произошла внутренняя ошибка сервера, скоро мы это исправим!');
        }
    }

    return error;
};

export const responseHandler = (response: AxiosResponse) => {
    if (response.status === 401) {
        history.push('/login');
    }

    return response;
};
