import React, { useEffect, useState } from 'react';

import { Empty, message, Tabs, Typography } from 'antd';
import { TabsPosition } from 'antd/es/tabs';

import AgroModelTab from './AgroModelTab';

import { api } from '../../http/API';
import { AgroModel } from '../../models/AgroModel';

const { TabPane } = Tabs;
const { Title } = Typography;

const AgroModelsPage = () => {
    let [agroModels, setAgroModels] = useState<AgroModel[]>([]);
    let [loaded, setLoaded] = useState(false);

    useEffect(() => {
        api.GetAgroModels()
            .then((res) => {
                setAgroModels(res);
                setLoaded(true);
            })
            .catch((_) => {
                message.error('Произошла ошибка при запросе моделей');
                setLoaded(true);
            });
    }, []);

    return (
        <>
            {loaded && agroModels.length === 0 ? (
                <div>
                    <Title level={3}>Нет доступных моделей</Title>
                    <Empty />
                </div>
            ) : (
                <Tabs defaultActiveKey="none" tabPosition={'top' as TabsPosition}>
                    {agroModels.map((am, i) => {
                        return (
                            <TabPane tab={`${am.name}`} key={i} disabled={!am.is_enabled}>
                                <AgroModelTab key={i} agroModel={am} />
                            </TabPane>
                        );
                    })}
                </Tabs>
            )}
        </>
    );
};

export default AgroModelsPage;
