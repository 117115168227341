import { createSlice } from '@reduxjs/toolkit';

import { Company } from '../models/Company';

export const CompanyStorage = createSlice({
    name: 'company',
    initialState: {
        company: Company,
    },
    reducers: {
        saveCompany: (state, action) => {
            state.company = action.payload;
        },
    },
});

export const { saveCompany } = CompanyStorage.actions;

interface RootState {
    company: Company;
}

export const selectCompany = (state: RootState) => state.company;

export default CompanyStorage.reducer;
