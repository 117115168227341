import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { Layout, Menu, PageHeader, Typography } from 'antd';

import {
    DesktopOutlined,
    PieChartOutlined,
    UserOutlined,
    RobotOutlined,
    AreaChartOutlined,
    QuestionOutlined,
    BarChartOutlined,
    BookOutlined,
    NotificationOutlined,
    ExperimentOutlined,
    LeftOutlined,
    LogoutOutlined,
    SelectOutlined,
    FundProjectionScreenOutlined,
    ControlOutlined,
    FileSearchOutlined,
} from '@ant-design/icons';

import { MenuInfo } from 'rc-menu/lib/interface';

import CompanyBrief from './company_brief/CompanyBrief';
import DevicesPage from './devices/DevicesPage';
import AgroModelsPage from './digital_helper/AgroModelsPage';
import DigitalHelperPage from './digital_helper/DigitalHelperPage';
import { HelpPage } from './help/HelpPage';
import IndexPage from './index/IndexPage';
import MonitorPage from './monitor/MonitorPage';
import NotificationsPage from './notifications/NotificationsPage';
import ReportsPage from './reports/ReportsPage';
import Wiki from './wiki/Wiki';

import packageInfo from '../../package.json';
import logo from '../assets/logo.svg';
import history from '../history';
import { User } from '../models/User';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

type StringStringMap = Record<string, string>;

const pageNames: StringStringMap = {
    '/': 'Главная страница',
    '/devices': 'Метеоустройства',
    '/settings': 'Настройки',
    '/help': 'Помощь',
    '/agromodels': 'Агромодели',
    '/digital_helper': 'Цифровой помощник',
    '/notifications': 'Уведомления',
    '/monitor': 'Панель мониторинга',
    '/reports': 'Создание отчётов',
    '/wiki': 'Агропогода Вики',
    '/company_brief': 'Сводка компаний',
};

const pageDescriptions: StringStringMap = {
    '/wiki': 'Внутренний справочник',
};

interface Props {
    user: User;
}

const ContentWrapper = (props: Props) => {
    const { user } = props;
    const path = window.location.pathname;

    const [collapsed, setCollapsed] = useState(false);
    const [page, setPage] = useState(path);
    const [pageName, setPageName] = useState(pageNames[path]);
    const [pageDescription, setPageDescription] = useState(pageDescriptions[path]);

    const [pageHistory, setPageHistory] = useState(['/']);

    const handleMenuClick = (e: MenuInfo) => {
        setPage(e.key);
        setPageName(pageNames[e.key]);
        setPageDescription(pageDescriptions[e.key]);

        history.push(e.key);
        setPageHistory([...pageHistory, e.key]);
    };

    const handleBackButtonClick = () => {
        let ph = [...pageHistory];
        ph.pop();

        let p = ph.pop();
        if (p) {
            ph.push(p);
            setPage(p);
            setPageName(pageNames[p]);
            setPageDescription(pageDescriptions[p]);
            setPageHistory(ph);
        }
        if (p === '/') {
            setPageHistory(['/']);
        }

        history.goBack();
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                theme="light"
                className="site-layout-sider"
                breakpoint="sm"
                collapsedWidth={50}
                collapsible
                collapsed={collapsed}
                onCollapse={() => setCollapsed(!collapsed)}
            >
                <div className="logo" style={{ padding: collapsed ? '12px 18px' : '12px 20px' }}>
                    <img
                        style={{ float: 'left', marginRight: collapsed ? 12 : 8 }}
                        src={logo}
                        alt={'logo'}
                        height={50}
                    />
                    {!collapsed && (
                        <h2 className="text" style={{ margin: 0 }}>
                            Агропогода
                        </h2>
                    )}
                    {!collapsed && (
                        <h5 className="text" style={{ margin: 0 }}>
                            Личный кабинет
                        </h5>
                    )}
                    <div style={{ clear: 'left' }} />
                </div>
                <Menu theme="light" defaultSelectedKeys={['1']} mode="inline" onClick={handleMenuClick}>
                    <Menu.Item key="/" icon={<UserOutlined />}>
                        Главная
                    </Menu.Item>
                    <Menu.Item key="/monitor" icon={<AreaChartOutlined />}>
                        Мониторинг
                    </Menu.Item>
                    <Menu.Item key="/devices" icon={<DesktopOutlined />}>
                        Метеоустройства
                    </Menu.Item>
                    <SubMenu icon={<RobotOutlined />} title="Агропомощник">
                        <Menu.Item key="/digital_helper" icon={<PieChartOutlined />}>
                            Метрики
                        </Menu.Item>
                        <Menu.Item disabled={false} key="/agromodels" icon={<ExperimentOutlined />}>
                            Агромодели
                        </Menu.Item>
                        <Menu.Item key="/notifications" icon={<NotificationOutlined />}>
                            Оповещения
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="/reports" icon={<BarChartOutlined />}>
                        Отчёты
                    </Menu.Item>
                    {user.is_admin && (
                        <Menu.Item key="/company_brief" icon={<FileSearchOutlined />}>
                            Сводки
                        </Menu.Item>
                    )}
                    {user.is_admin && (
                        <SubMenu icon={<ControlOutlined />} title="Служебное">
                            <Menu.Item key="/admin" icon={<SelectOutlined />}>
                                Админка
                            </Menu.Item>
                            <Menu.Item key="/grafana" icon={<FundProjectionScreenOutlined />}>
                                Графана
                            </Menu.Item>
                            {/*<Menu.Item key="/emails" icon={<SendOutlined />} disabled>*/}
                            {/*    Рассылки*/}
                            {/*</Menu.Item>*/}
                        </SubMenu>
                    )}
                    {user.is_admin && (
                        <Menu.Item key="/wiki" icon={<BookOutlined />}>
                            Вики
                        </Menu.Item>
                    )}
                    {/*<Menu.Item key="/settings" icon={<SettingOutlined />} disabled>*/}
                    {/*    Настройки*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item key="/help" icon={<QuestionOutlined />}>
                        Помощь
                    </Menu.Item>
                    <Menu.Item key="/logout" icon={<LogoutOutlined />}>
                        Выход
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Content style={{ margin: collapsed ? '0 6px' : '0 16px' }}>
                    <PageHeader
                        style={{ marginLeft: '-16px' }}
                        className="site-page-header"
                        backIcon={page !== '/' ? <LeftOutlined /> : false}
                        onBack={() => handleBackButtonClick()}
                        title={pageName}
                        subTitle={pageDescription}
                    />
                    <div className="site-layout-content">
                        <Route exact path="/" component={IndexPage} />
                        <Route exact path="/monitor" component={MonitorPage} />
                        <Route exact path="/monitor/:id" component={MonitorPage} />
                        <Route exact path="/devices" component={DevicesPage} />
                        <Route exact path="/reports" component={ReportsPage} />
                        <Route exact path="/digital_helper" component={DigitalHelperPage} />
                        <Route exact path="/agromodels" component={AgroModelsPage} />
                        <Route exact path="/help" component={HelpPage} />
                        <Route exact path="/notifications" component={NotificationsPage} />
                        <Route exact path="/wiki" component={Wiki} />
                        <Route exact path="/company_brief" component={CompanyBrief} />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    <Text type="secondary">© OOO "УМИУМ", 2019-2022 (версия v{packageInfo.version})</Text>
                </Footer>
            </Layout>
        </Layout>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.user,
    };
};

export default connect(mapStateToProps)(ContentWrapper);
