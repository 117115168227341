import { sortArrayByKey } from './functions';

import { User } from '../models/User';
import { Zond } from '../models/Zond';

export const filterZondsByUserRole = (zonds: Zond[], user: User, zondID: number | null): Zond[] => {
    let filteredZonds = [];

    if (zondID) {
        let z = zonds.find((e) => e.id === zondID);
        if (z) filteredZonds.push(z);
    } else {
        for (let zond of zonds) {
            if (!user.is_admin && zond.zond_status_id > 1) {
                // do NOT take unused devices for users
            } else {
                filteredZonds.push(zond);
            }
        }

        filteredZonds = sortArrayByKey(filteredZonds, 'number');
        filteredZonds = sortArrayByKey(filteredZonds, 'zond_type_id');
    }

    return filteredZonds;
};
