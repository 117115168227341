import React, { useState } from 'react';

import { Select } from 'antd';

import { Company } from '../models/Company';
import { User } from '../models/User';

const { Option } = Select;

interface Props {
    user: User;
    companies: Company[];
    selectedRegion: number;
    handleChangeCompany: (s: string) => void;
}

export default function CompanySelect(props: Props) {
    const [search, setSearch] = useState('');

    return (
        <Select
            disabled={props.selectedRegion > 0 ? false : true}
            showSearch={true}
            onChange={(value) => value && props.handleChangeCompany(value.toString())}
            placeholder="Выберите хозяйство"
            style={{ width: 348, maxWidth: '100%' }}
            size="large"
            onSearch={(value) => setSearch(value)}
        >
            {props.companies.map((company) => {
                if (company.location_region_id == props.selectedRegion)
                    return (
                        <Option value={company.name} key={company.id}>
                            {company.name}
                        </Option>
                    );
            })}
        </Select>
    );
}
