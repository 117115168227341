import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Map, Placemark, Polygon, YMaps } from 'react-yandex-maps';

import { Progress, Radio, Space, Divider, Typography } from 'antd';

import postIcon from '../../../assets/map_icons/post.png';
import cloudyPostIcon from '../../../assets/map_icons/weather/cloudy_post.png';
import cloudySnowPostIcon from '../../../assets/map_icons/weather/cloudy_snow_post.png';
import lRainyPostIcon from '../../../assets/map_icons/weather/l_rainy_post.png';
import rainyPostIcon from '../../../assets/map_icons/weather/rainy_post.png';
import stormPostIcon from '../../../assets/map_icons/weather/storm_post.png';
import sunnyCloudyPostIcon from '../../../assets/map_icons/weather/sunny_cloudy_post.png';
import sunnyCloudySnowPostIcon from '../../../assets/map_icons/weather/sunny_cloudy_snow_post.png';
import sunnyPostIcon from '../../../assets/map_icons/weather/sunny_post.png';
import sunnyRainPostIcon from '../../../assets/map_icons/weather/sunny_rain_post.png';
import sunnySnowPostIcon from '../../../assets/map_icons/weather/sunny_snow_post.png';
import zondIconV0 from '../../../assets/map_icons/zondV0.png';
import zondIconV1 from '../../../assets/map_icons/zondV1.png';
import { arrayAverageByKey, zip } from '../../../helpers/functions';
import { api } from '../../../http/API';
import { monitorAPI } from '../../../http/MonitorAPI';
import { Field } from '../../../models/Field';
import { Graph } from '../../../models/Graph';
import { User } from '../../../models/User';
import { Zond } from '../../../models/Zond';

import moment from 'moment';

const { Title } = Typography;

interface Props {
    zonds: Zond[];
    user: User;
}

export const YandexMap = (props: Props) => {
    let [zonds, setZonds] = useState(props.zonds);
    let [field, setField] = useState<Field>(new Field());
    let [fetched, setFetched] = useState<boolean>(false);
    let [useSatellite, setUseSatellite] = useState<boolean>(false);
    let [progress, setProgress] = useState<number>(0);

    const mounted = useRef(false);

    const fetchMetrics = useCallback(async () => {
        if (!fetched) {
            let zondsCopy = [...zonds];
            for (let i = 0; i < zondsCopy.length; i++) {
                setProgress(Math.ceil((i / zondsCopy.length) * 100));
                let zond = Object.assign({}, zondsCopy[i]);

                if (zond.zond_type.displayed_name === 'Пост') {
                    await monitorAPI
                        .GetWeather(zond['number_in_db'])
                        .then((res) => {
                            zond['weather'] = res.Weather;
                        })
                        .catch((err) => {
                            // it's fine to get an error here
                            console.log(err);
                        });
                }

                await monitorAPI
                    .GetLastMetrics(zond['number_in_db'])
                    .then((metrics) => {
                        if (metrics) {
                            zond['last_metrics'] = parseMetrics(metrics, zond);
                        } else {
                            console.log(`error reading data from ${zond.number}`);
                            zond['last_metrics'] = 'Не удалось получить последние данные';
                        }
                    })
                    .catch((err) => {
                        // also fine
                        console.log(err);
                        zond['last_metrics'] = 'Не удалось получить последние данные';
                    });
                zondsCopy[i] = zond;

                if (!mounted.current) return; // circuit breaker
            }

            setFetched(true);
            setZonds(zondsCopy);
        }
    }, [fetched, zonds]);

    useEffect(() => {
        api.GetFieldOfCompany()
            .then((resp) => {
                resp.features = changeLongitudeAndLatitudePositions(resp.features);
                setField(resp);
            })
            .catch(() => console.log('no field for company'));
    }, []);

    useEffect(() => {
        fetchMetrics().then(() => console.log('metrics fetched'));
    }, [fetchMetrics]);

    useEffect(() => {
        mounted.current = true; // Will set it to true on mount ...
        return () => {
            mounted.current = false;
        }; // ... and to false on unmount
    }, []);

    return (
        <>
            <YMaps>
                {fetched ? (
                    <div style={styles.mapLayout}>
                        <Map
                            height="600px"
                            width="-1"
                            key={useSatellite ? 'yandex#satellite' : 'yandex#map'}
                            defaultState={{
                                center: [arrayAverageByKey(zonds, 'latitude'), arrayAverageByKey(zonds, 'longitude')],
                                zoom: zonds.length > 15 ? 8 : 11,
                                type: useSatellite ? 'yandex#satellite' : 'yandex#map',
                            }}
                            modules={['templateLayoutFactory', 'layout.ImageWithContent']}
                        >
                            {zonds.map((zond, i) => {
                                if (zond.latitude && zond.longitude)
                                    return (
                                        <Placemark
                                            key={i}
                                            geometry={[zond.latitude, zond.longitude]}
                                            properties={{
                                                hintContent: `${zond['zond_type'].displayed_name} №${zond.number}`,
                                                balloonContent: zond.last_metrics,
                                            }}
                                            options={{
                                                iconLayout: 'default#image',
                                                iconImageHref: getMapIcon(zond, props.user.is_admin),
                                                iconImageSize: zond.zond_type_id === 4 ? [44, 54] : [30, 40],
                                            }}
                                            modules={[
                                                'geoObject.addon.balloon',
                                                'geoObject.addon.hint',
                                                'layout.ImageWithContent',
                                            ]}
                                            controls={['zoomControl']}
                                        />
                                    );
                                else return '';
                            })}
                            {field.features.map((f, i) => {
                                if (f.geometry.type === 'Polygon') {
                                    return (
                                        <Polygon
                                            key={i}
                                            geometry={f.geometry.coordinates}
                                            properties={{
                                                hintContent: f.properties['description'],
                                                balloonContent: f.properties['description'],
                                            }}
                                            options={{
                                                description: f.properties['description'],
                                                fillColor: f.properties['fill'],
                                                strokeColor: f.properties['stroke'],
                                                opacity: f.properties['fill-opacity'],
                                                strokeWidth: f.properties['stroke-width'],
                                                strokeOpacity: f.properties['stroke-opacity'],
                                            }}
                                            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                        />
                                    );
                                } else return '';
                            })}
                        </Map>
                    </div>
                ) : (
                    <>
                        <Title level={4}>Загружаем карту с устройствами...</Title>
                        <Progress percent={progress} />
                    </>
                )}
            </YMaps>
            {fetched ? (
                <>
                    <Divider />
                    <Radio.Group value={useSatellite} onChange={(event) => setUseSatellite(event.target.value)}>
                        <Space direction="vertical">
                            <Radio value={false}>Карта</Radio>
                            <Radio value={true}>Спутник</Radio>
                        </Space>
                    </Radio.Group>
                </>
            ) : (
                ''
            )}
        </>
    );
};

const styles = {
    mapLayout: {
        boxShadow: '2px 2px 5px rgba(0,0,0,0.3)',
        borderRadius: '5px',
    },
};

function changeLongitudeAndLatitudePositions(features: any[]): any[] {
    for (let i = 0; i < features.length; i++) {
        for (let j = 0; j < features[i].geometry.coordinates.length; j++) {
            for (let k = 0; k < features[i].geometry.coordinates[j].length; k++) {
                features[i].geometry.coordinates[j][k] = features[i].geometry.coordinates[j][k].reverse();
            }
        }
    }
    return features;
}

function parseMetrics(metrics: Graph[], zond: Zond): string {
    let result = '';
    result += `<u> ${zond['zond_type']['displayed_name']} №${zond['number']}</u><br /><br />`;

    for (let g of metrics) {
        if (g['Values']) {
            result += `<b>${g['MeasurementType']}</b><br />`;
            let zipped = zip(g['Legends'].slice(1), g['Values'][0].slice(1));
            for (let m of zipped) {
                if (m[1] !== null) {
                    result += `${m[0]}: ${m[1].toFixed(1)} <br />`;
                }
            }
        }
    }

    if (metrics[0]['Values'] && metrics[0]['Values'][0][0]) {
        let date = moment(metrics[0]['Values'][0][0] * 1000).format('LLL');
        result += `<br /><i>Измерено: ${date}</i>`;
    }

    return result;
}

function getMapIcon(zond: Zond, userIsAdmin = false): string {
    if (zond.zond_type.displayed_name === 'Зонд') {
        // новые зонды рисуем синим цветом для администратора
        if (userIsAdmin && zond.zond_type_id && zond.zond_type_id > 5) return zondIconV1;
        else return zondIconV0;
    }

    switch (zond.weather) {
        case 'sunny':
            return sunnyPostIcon;
        case 'cloudy':
            return cloudyPostIcon;
        case 'sunny_l_rain':
            return sunnyRainPostIcon;
        case 'sunny_rain':
            return sunnyRainPostIcon;
        case 'sunny_cloudy':
            return sunnyCloudyPostIcon;
        case 'l_rain':
            return lRainyPostIcon;
        case 'rain':
            return rainyPostIcon;
        case 'storm':
            return stormPostIcon;
        case 'shower':
            return rainyPostIcon;
        case 'sunny_cloudy_snow':
            return sunnyCloudySnowPostIcon;
        case 'sunny_snow':
            return sunnySnowPostIcon;
        case 'cloudy_snow':
            return cloudySnowPostIcon;
        default:
            return postIcon;
    }
}
