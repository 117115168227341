import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Alert, Button, Input, Col, Row, Collapse, Divider, message, Checkbox } from 'antd';

import { AirPressureLineChart } from './components/AirPressureLineChart';
import { ResponsiveBarChart } from './components/ResponsiveBarChart';
import { ResponsiveLineChart } from './components/ResponsiveLineChart';
import { ResponsiveRadarChart } from './components/ResponsiveRadarChart';
import { ZoomLineChart } from './components/ZoomLineChart';

import DeviceSelect from '../../components/DeviceSelect';
import TimePeriod from '../../components/TimePeriod';
import TimeRange from '../../components/TimeRange';
import { exportCSVFile } from '../../helpers/CSV';
import { inflateLegends, inflateValues } from '../../helpers/MonitorCSVinflaters';
import { monitoringTimePeriods } from '../../helpers/constants';
import { filterZondsByUserRole } from '../../helpers/filters';
import { forecastAPI } from '../../http/ForecastAPI';
import { monitorAPI } from '../../http/MonitorAPI';
import { Graph } from '../../models/Graph';
import { User } from '../../models/User';
import { Zond } from '../../models/Zond';

import moment from 'moment';

const { Panel } = Collapse;

interface Props extends RouteComponentProps<{ id: string }> {
    user: User;
    zonds: Zond[];
}

const MonitorPage = (props: Props) => {
    const zondID = parseInt(props.match.params.id);

    const user: User = props.user;
    const zonds: Zond[] = filterZondsByUserRole(props.zonds, props.user, zondID);

    let [graphs, setGraphs] = useState<Graph[]>([]);

    let [selectedZond, setSelectedZond] = useState('');
    let [selectedTime, setSelectedTime] = useState(6); // hours

    let [useDates, setUseDates] = useState(false);
    let [predictionEnabled, setPredictionEnabled] = useState(false);
    let [startDate, setStartDate] = useState('');
    let [endDate, setEndDate] = useState('');

    const fetchMetrics = useCallback(async () => {
        if (!selectedZond) return;

        let end = Math.floor(new Date().valueOf() / 1000); // now
        let start = Math.floor(new Date(new Date().getTime() - selectedTime * 60 * 1000 * 60).valueOf() / 1000);

        if (useDates) {
            if (!endDate || !startDate) return;
            end = moment(endDate).unix();
            start = moment(startDate).unix();
        }

        await monitorAPI
            .GetMetrics(selectedZond, start, end)
            .then(async (resp) => {
                if (resp) {
                    if (predictionEnabled && resp[0].Values.length <= 100) {
                        try {
                            // this API is really unreliable...
                            resp = await forecastAPI.Predict(resp);
                        } catch (err) {
                            // ... and this is completely fine
                            console.log(err);
                        }
                    }
                    setGraphs(resp);
                }
            })
            .catch((error) => {
                console.log(error);
                message.warning('Нет данных за выбранный период');
                setGraphs([]);
            });
    }, [selectedTime, selectedZond, startDate, endDate, useDates, predictionEnabled]);

    const handleChangeZond = (s: string) => {
        setSelectedZond(s);
    };

    const handleChangeTime = (t: string) => {
        setSelectedTime(monitoringTimePeriods[t]);
        setUseDates(false);
    };

    useEffect(() => {
        fetchMetrics().then(() => console.log(selectedZond, selectedTime));
    }, [fetchMetrics, selectedZond, selectedTime, startDate, endDate]);

    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={16}>
                    <Input.Group compact>
                        <DeviceSelect user={user} zonds={zonds} handleChangeZond={handleChangeZond} />
                        <TimePeriod
                            handleChangeTime={handleChangeTime}
                            useDates={useDates}
                            monitoringTimePeriods={monitoringTimePeriods}
                        />
                    </Input.Group>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <Collapse>
                        <Panel header="Дополнительные опции" key="1">
                            <TimeRange
                                size="default"
                                onChange={(dates) => {
                                    setUseDates(true);
                                    // @ts-ignore
                                    setStartDate(dates[0].unix() * 1000);
                                    // @ts-ignore
                                    setEndDate(dates[1].unix() * 1000);
                                }}
                            />

                            <Divider dashed={true} style={{ margin: 8 }} />
                            <Checkbox
                                checked={predictionEnabled}
                                onChange={() => setPredictionEnabled(!predictionEnabled)}
                            >
                                Краткосрочные прогнозы
                            </Checkbox>
                            <br />
                            <br />
                            <Button
                                disabled={!graphs.length}
                                onClick={() =>
                                    exportCSVFile(inflateLegends(graphs), inflateValues(graphs), selectedZond)
                                }
                            >
                                Экспорт в CSV
                            </Button>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={24}>
                    {graphs.map((graph, i) => {
                        let render = <div />;
                        if (graph['Values'] == null) {
                            return (
                                <Alert
                                    type="error"
                                    key={i}
                                    message={`Не удалось получить последние данные для метрики "${graph['MeasurementType']}"`}
                                />
                            );
                        } else
                            switch (graph['ChartType']) {
                                case 'RadarChart':
                                    render = <ResponsiveRadarChart graph={graph} />;
                                    break;
                                case 'ZoomLineChart':
                                    render = <ZoomLineChart graph={graph} />;
                                    break;
                                case 'BarChart':
                                    render = <ResponsiveBarChart graph={graph} />;
                                    break;
                                case 'AirPressureLineChart':
                                    render = (
                                        <AirPressureLineChart
                                            graph={graph}
                                            isOldZond={selectedZond !== '' && selectedZond[0] !== 'Z'}
                                        />
                                    );
                                    break;
                                default:
                                    render = <ResponsiveLineChart graph={graph} />;
                            }
                        return (
                            <Col key={i} className="mb-4 col-12 single-chart">
                                <h2>{graph['MeasurementType']}</h2>
                                {render}
                            </Col>
                        );
                    })}
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        zonds: state.zonds.zonds,
        user: state.user.user,
    };
};

export default connect(mapStateToProps)(MonitorPage);
