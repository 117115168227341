import axios from 'axios';

import { forecastApiAddress } from '../config';
import { Graph } from '../models/Graph';

export class ForecastAPI {
    Predict(data: Graph[]): Promise<Graph[]> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${forecastApiAddress}/predict`, data, { timeout: 7000 })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}

export const forecastAPI = new ForecastAPI();
