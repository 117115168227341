import React from 'react';

import { Col, Typography } from 'antd';

import { DigitalHelper } from '../../../models/DigitalHelper';

const { Title } = Typography;

interface Props {
    metrics: DigitalHelper;
}

export const GrowingSeasonHeatSupplyPanel = (props: Props) => {
    const metrics = props.metrics;

    return (
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <Title level={5}>Сумма полезных температур</Title>
            <ul>
                <li>
                    выше 10°C:
                    <b> {metrics['GrowingSeasonHeatSupply']['T10']} °C</b>
                </li>
                <li>
                    выше 5°C:
                    <b> {metrics['GrowingSeasonHeatSupply']['T5']} °C</b>
                </li>
                <li>
                    выше 0°C:
                    <b> {metrics['GrowingSeasonHeatSupply']['T0']} °C</b>
                </li>
            </ul>
        </Col>
    );
};
