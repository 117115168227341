import { Graph } from '../models/Graph';

import moment from 'moment';

export function inflateLegends(graphs: Graph[][]) {
    let result = graphs
        .map(function (e) {
            return e[0]['Legends'];
        })
        .flat()
        .map(function (e) {
            return e.replace(',', '');
        });

    result.unshift('Тип');
    return result;
}

export function inflateValues(graphs: Graph[][]) {
    let g = prepareDate(graphs);
    let data = g[0][0];
    const types = ['min', 'max', 'avg'];

    let results = [];
    for (let i = 0; i < data['Values'].length; i++) {
        for (let t = 0; t < types.length; t++) {
            let r = [];
            for (let j = 0; j < g.length; j++) {
                r.push(g[j][t]['Values'][i]);
            }
            r = r.flat();
            r.unshift(types[t]);
            results.push(r);
        }
    }
    return results;
}

function prepareDate(metrics: any[]) {
    for (let j = 0; j < metrics.length; j++) {
        for (let t = 0; t < metrics[j].length; t++) {
            for (let i = 0; i < metrics[j][t]['Values'].length; i++) {
                if (!isNaN(metrics[j][t]['Values'][i][0]))
                    metrics[j][t]['Values'][i][0] = moment(metrics[j][t]['Values'][i][0] * 1000).format('L');
            }
        }
    }
    return metrics;
}
