export class WikiPage {
    id: number = 0;
    title: string = '';
    text: string = '';
    is_markdown: boolean = false;
}

export class WikiSection {
    id: number = 0;
    root_section_id: number | null = 0;
    name: string = '';
    description: string = '';
    child_sections: WikiSection[] = [];
    wiki_pages: WikiPage[] = [];
}
