import React from 'react';

import { Select } from 'antd';

const { Option } = Select;

type StringNumberMap = Record<string, number>;

interface Props {
    handleChangeTime: (s: string) => void;
    useDates: boolean;
    monitoringTimePeriods: StringNumberMap;
}

export default function TimePeriod(props: Props) {
    return (
        <Select
            disabled={props.useDates}
            onChange={(value) => value && props.handleChangeTime(value.toString())}
            placeholder="Временной период"
            style={{ width: 240, maxWidth: '100%' }}
            size="large"
        >
            {Object.keys(props.monitoringTimePeriods).map((elem, i) => {
                return (
                    <Option value={elem} key={i}>
                        {elem}
                    </Option>
                );
            })}
        </Select>
    );
}
