import { zip } from './functions';

import { Graph } from '../models/Graph';

import moment from 'moment';

export function inflateLegends(graphs: Graph[]) {
    return zip(
        graphs.map(function (e) {
            return e['Legends'];
        }),
    )
        .flat()
        .flat()
        .map(function (e) {
            return e.replace(',', '');
        })
        .filter(function(item, pos, self) {
            // deduplication
            return self.indexOf(item) === pos;
        });
}

export function inflateValues(graphs: Graph[]) {
    if (!graphs[0]['Values']) return [];
    let result = [];

    let g = prepareDate(graphs);
    let data = g.map(function (e, i) {
        if (i === 0) return e['Values'];
        return e['Values'].map((v: string | any[]) => v.slice(1));
    });

    for (let elem of zip(...data)) {
        result.push(elem.flat());
    }

    return result;
}

function prepareDate(graphs: any[]) {
    for (let j = 0; j < graphs.length; j++) {
        for (let t = 0; t < graphs[j]['Values'].length; t++) {
            if (!isNaN(graphs[j]['Values'][t][0]))
                graphs[j]['Values'][t][0] = moment(graphs[j]['Values'][t][0] * 1000).format("DD.MM.YYYY hh:mm");
        }
    }
    return graphs;
}
