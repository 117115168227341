import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, Col, Collapse, Divider, Input, Row, Table, Typography, Checkbox, Spin, message } from 'antd';

import DeviceSelect from '../../components/DeviceSelect';
import TimeRange from '../../components/TimeRange';
import { exportCSVFile } from '../../helpers/CSV';
import { inflateLegends, inflateValues } from '../../helpers/ReportsCSVinflaters';
import { zip } from '../../helpers/functions';
import { monitorAPI } from '../../http/MonitorAPI';
import { Company } from '../../models/Company';
import { Graph } from '../../models/Graph';
import { User } from '../../models/User';
import { Zond } from '../../models/Zond';

import moment from 'moment';

const { Panel } = Collapse;
const { Title } = Typography;

interface Props {
    company: Company;
    user: User;
    zonds: Zond[];
}

const summarizeMetrics = ['Солнечная активность', 'Уровень осадков'];

const ReportsPage = (props: Props) => {
    let [selectedZond, setSelectedZond] = useState<string>('');
    let [startDate, setStartDate] = useState<number | null>(null);
    let [endDate, setEndDate] = useState<number | null>(null);
    let [loaderOn, setLoaderOn] = useState<boolean>(false);
    let [graphs, setGraphs] = useState<Graph[][]>([]);
    let [useUTC, setUseUTC] = useState<boolean>(false);

    const fetchMetrics = useCallback(async () => {
        if (startDate && endDate && selectedZond) {
            setLoaderOn(true);
            setGraphs([]);

            const tz = useUTC ? '0' : '7';
            let mins, maxs, avgs, sums;

            try {
                mins = await monitorAPI.GetMetricsWithFunction(selectedZond, startDate, endDate, 'min', tz);
                maxs = await monitorAPI.GetMetricsWithFunction(selectedZond, startDate, endDate, 'max', tz);
                avgs = await monitorAPI.GetMetricsWithFunction(selectedZond, startDate, endDate, 'avg', tz);
                sums = await monitorAPI.GetMetricsWithFunction(selectedZond, startDate, endDate, 'sum', tz);
            } catch (error) {
                message.error('Произошла ошибка при запросе данных, повторите попытку позже');
                setLoaderOn(false);
                return;
            }

            if (!(mins && maxs && avgs)) {
                message.error('Произошла ошибка при запросе данных, повторите попытку позже');
                setLoaderOn(false);
                return;
            }

            if (mins[0]['Values'] !== null) {
                setGraphs(zip(mins, maxs, avgs, sums));
            } else {
                message.error('По вашему запросу не получено данных');
            }
            setLoaderOn(false);
        }
    }, [endDate, selectedZond, startDate, useUTC]);

    const handleChangeZond = (z: string) => {
        setSelectedZond(z);
    };

    useEffect(() => {
        fetchMetrics().then(() => console.log(selectedZond, startDate, endDate, useUTC));
    }, [fetchMetrics, startDate, endDate, selectedZond, useUTC]);

    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={16}>
                    <Input.Group compact>
                        <DeviceSelect user={props.user} zonds={props.zonds} handleChangeZond={handleChangeZond} />
                        <TimeRange
                            size="large"
                            onChange={(dates) => {
                                // @ts-ignore
                                setStartDate(dates[0].unix());
                                // @ts-ignore
                                setEndDate(dates[1].unix());
                            }}
                        />
                    </Input.Group>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <Collapse className="reports-page-collapse">
                        <Panel header="Дополнительные опции" key="1">
                            <Checkbox checked={useUTC} onChange={() => setUseUTC(!useUTC)}>
                                По Гринвичу
                            </Checkbox>
                            <br />
                            <br />
                            <Button
                                disabled={!graphs.length}
                                onClick={() =>
                                    exportCSVFile(inflateLegends(graphs), inflateValues(graphs), selectedZond)
                                }
                            >
                                Экспорт в CSV
                            </Button>
                            <Button
                                disabled={!graphs.length}
                                onClick={() => {
                                    window.print();
                                    return false;
                                }}
                            >
                                {' '}
                                Печать
                            </Button>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Divider />
            {loaderOn && <Spin size="large" />}
            {graphs.map((graph, i) => {
                return (
                    <div key={i}>
                        <Title level={3}>{graph[0]['MeasurementType']}</Title>
                        <Table
                            pagination={false}
                            size={'small'}
                            scroll={{ y: 300, x: 'max-content' }}
                            dataSource={createDataSource(graph)}
                            columns={createColumns(graph)}
                        />
                        <Divider />
                    </div>
                );
            })}
        </>
    );
};

const createDataSource = (graph: Graph[]) => {
    let result = [];
    for (let v of zip(graph[0]['Values'], graph[1]['Values'], graph[2]['Values'], graph[3]['Values'])) {
        result.push({
            key: v[0][0] * 1000,
            Время: moment(v[0][0] * 1000).format('L'),
        });

        if (summarizeMetrics.includes(graph[0]['MeasurementType'])) {
            let o: Record<string, number | string> = {};
            for (let pair of zip(graph[0]['Legends'], v[3])) {
                if (pair[0] === 'Время') {
                    o['Время'] = 'сумма';
                } else {
                    o[pair[0]] = pair[1] && pair[1].toFixed(1);
                }
            }
            result.push(o);
        } else {
            let o0: Record<string, number | string> = {};
            for (let pair of zip(graph[0]['Legends'], v[0])) {
                if (pair[0] === 'Время') {
                    o0['Время'] = 'минимум';
                } else {
                    o0[pair[0]] = pair[1] && pair[1].toFixed(1);
                }
            }
            result.push(o0);

            let o1: Record<string, number | string> = {};
            for (let pair of zip(graph[0]['Legends'], v[1])) {
                if (pair[0] === 'Время') {
                    o1['Время'] = 'максимум';
                } else {
                    o1[pair[0]] = pair[1] && pair[1].toFixed(1);
                }
            }
            result.push(o1);

            let o2: Record<string, number | string> = {};
            for (let pair of zip(graph[0]['Legends'], v[2])) {
                if (pair[0] === 'Время') {
                    o2['Время'] = 'среднее';
                } else {
                    o2[pair[0]] = pair[1] && pair[1].toFixed(1);
                }
            }
            result.push(o2);
        }
    }

    return result;
};

const createColumns = (graph: Graph[]) => {
    let result = [];
    for (let l of graph[0]['Legends']) {
        let o = {
            title: l.replaceAll('Время', 'Дата/результат'),
            dataIndex: l,
            key: l,
        };

        if (o['title'] === 'Дата/результат') {
            // @ts-ignore
            o.render = (text: string) => {
                if (!['минимум', 'максимум', 'среднее', 'сумма'].includes(text)) {
                    return <strong>{text}</strong>;
                } else return text;
            };
        }

        result.push(o);
    }

    return result;
};

const mapStateToProps = (state: any) => {
    return {
        company: state.company.company,
        user: state.user.user,
        zonds: state.zonds.zonds,
    };
};

export default connect(mapStateToProps)(ReportsPage);
