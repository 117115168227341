import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import { Button, message, Typography, List, Modal } from 'antd';

import { api } from '../../http/API';
import { WikiPage, WikiSection } from '../../models/Wiki';

const { Title } = Typography;

const Wiki = () => {
    const [wikiSectionID, setWikiSectionID] = useState(1);
    const [wikiSection, setWikiSection] = useState<WikiSection>(new WikiSection());

    const [loaded, setLoaded] = useState(false);
    const [selectedPage, setSelectedPage] = useState<WikiPage | null>(null);

    useEffect(() => {
        setLoaded(false);
        api.GetWikiSection(wikiSectionID)
            .then((res) => {
                setWikiSection(res);
                setLoaded(true);
            })
            .catch((_) => {
                message.error('Произошла ошибка при запросе раздела');
                setLoaded(true);
            });
    }, [wikiSectionID]);

    return (
        <>
            <Title level={3}>{wikiSection.name}</Title>
            {wikiSection.child_sections.length > 0 && (
                <List
                    header={<Title level={5}>Подразделы</Title>}
                    itemLayout="horizontal"
                    dataSource={wikiSection.child_sections}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <Button onClick={() => setWikiSectionID(item.id)} type="link">
                                        {item.name}
                                    </Button>
                                }
                            />
                        </List.Item>
                    )}
                />
            )}
            {wikiSection.wiki_pages.length > 0 && (
                <List
                    header={<Title level={5}>Страницы</Title>}
                    itemLayout="horizontal"
                    dataSource={wikiSection.wiki_pages}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <Button onClick={() => setSelectedPage(item)} type="link">
                                        {item.title}
                                    </Button>
                                }
                            />
                        </List.Item>
                    )}
                />
            )}

            {loaded && wikiSection.root_section_id !== null && (
                <Button
                    onClick={() => {
                        setWikiSectionID(wikiSection.root_section_id ? wikiSection.root_section_id : 1);
                    }}
                >
                    Назад
                </Button>
            )}

            <Modal
                title={selectedPage?.title}
                visible={selectedPage !== null}
                onCancel={() => setSelectedPage(null)}
                cancelButtonProps={{ style: { display: 'none' } }}
                onOk={() => setSelectedPage(null)}
            >
                <ReactQuill value={selectedPage?.text} readOnly={true} theme={'bubble'} />
            </Modal>
        </>
    );
};

export default Wiki;
