import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { message } from 'antd';

import history from './history';
import { api } from './http/API';
import ContentWrapper from './pages/ContentWrapper';
import LoginPage from './pages/login/LoginPage';
import { saveCompany } from './storage/CompanyStorage';
import { saveUser } from './storage/UserStorage';
import { saveZonds } from './storage/ZondsStorage';

import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            api.GetUser()
                .then((res) => dispatch(saveUser(res)))
                .catch((_) => {
                    localStorage.removeItem('token');
                    history.push('/login');
                });

            api.GetCompany()
                .then((res) => dispatch(saveCompany(res)))
                .catch((_) => {
                    localStorage.removeItem('token');
                    history.push('/login');
                });
            api.GetZonds()
                .then((res) => dispatch(saveZonds(res)))
                .catch((_) => {
                    localStorage.removeItem('token');
                    history.push('/login');
                });
        } else {
            history.push('/login');
        }
    }, [dispatch]);

    return (
        <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/admin" component={AdminRedirect} />
            <Route exact path="/grafana" component={GrafanaRedirect} />
            <Route path="/" component={ContentWrapper} />
        </Switch>
    );
}

function Logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('verbose');
    return <Redirect to={'/login'} />;
}

function AdminRedirect() {
    window.open('https://admin.agropogoda.com', '_blank');
    return <Redirect to={'/'} />;
}

function GrafanaRedirect() {
    window.open('https://grafana.agropogoda.com', '_blank');
    return <Redirect to={'/'} />;
}

export default withRouter(App);
