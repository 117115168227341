import React from 'react';

import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';

import { preprocessDataForRadarChart } from '../../../helpers/RechartsPreprocess';
import { Graph } from '../../../models/Graph';

interface Props {
    graph: Graph;
}

export const ResponsiveRadarChart = (props: Props) => {
    const graph = props.graph;

    return (
        <ResponsiveContainer width="100%" height={400}>
            <RadarChart data={preprocessDataForRadarChart(graph['Values'])}>
                <PolarGrid />
                <PolarAngleAxis dataKey="Direction" />
                <PolarRadiusAxis />
                <Radar name="Направление ветра" dataKey="Amount" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                <Radar
                    name="Последнее направление"
                    dataKey="LastDirection"
                    stroke="#cc0000"
                    fill="#ff0000"
                    fillOpacity={0.6}
                    strokeWidth="4"
                />
                <Legend />
            </RadarChart>
        </ResponsiveContainer>
    );
};
