import React from 'react';

import { Carousel, Typography } from 'antd';

import { CarouselItem } from './CarouselItem';

import { Company } from '../../../models/Company';
import { Zond } from '../../../models/Zond';

const { Title } = Typography;

interface Props {
    company: Company;
    zonds: Zond[];
}

export const DailyReportCarousel = (props: Props) => {
    return (
        <>
            <Title level={4}>Суточная сводка {props.company.name}</Title>
            <Carousel autoplaySpeed={6000} autoplay>
                {props.zonds.map((z, i) => {
                    if (z.zond_type_id === 4 && z.zond_status.is_working) {
                        return <CarouselItem post={z} />;
                    } else return '';
                })}
            </Carousel>
        </>
    );
};
