import React from 'react';

import { Typography } from 'antd';

const { Title, Paragraph, Link } = Typography;

export const AboutUpdate = () => {
    return (
        <>
            <Typography style={{ fontSize: '1.2em' }}>
                <Title level={3}>Что нового?</Title>
                <Paragraph>
                    Мы изменили интерфейс, однако все важные функции остались на своих местах:
                    <ul>
                        <li>Новое меню позволяет быстрее переключаться между нужными вкладками;</li>
                        <li>Теперь карта с устройства и графики занимают бóльшую часть экрана;</li>
                        <li>Многие панели (особенно отчёты) теперь отображаются более аккуратно.</li>
                    </ul>
                </Paragraph>
            </Typography>
            <Typography>
                <Paragraph>
                    О любых проблемах и трудностях, возникших с новым интерфейсом, вы можете сообщать на почту&nbsp;
                    <Link href="mailto:info@umium.com" target="_blank">
                        info@umium.com
                    </Link>
                </Paragraph>
            </Typography>
        </>
    );
};
