import React from 'react';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { preprocessDataForLineChart } from '../../../helpers/RechartsPreprocess';
import { materialColorPalette } from '../../../helpers/constants';
import { Graph } from '../../../models/Graph';

interface Props {
    graph: Graph;
}

export const ResponsiveBarChart = (props: Props) => {
    const graph = props.graph;

    return (
        <ResponsiveContainer width="98%" height={400}>
            <BarChart data={preprocessDataForLineChart(graph['Values'], graph['Legends'])}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={graph['Legends'][0]} />
                <YAxis />
                <Tooltip />
                <Legend />
                {graph['Legends'].map((legend, i) => {
                    if (i >= 1) {
                        if (legend.startsWith('(прогноз)')) {
                            return <></>;
                        } else return <Bar key={i} dataKey={legend} fill={materialColorPalette[i]} />;
                    } else return <span key={i} />;
                })}
            </BarChart>
        </ResponsiveContainer>
    );
};
