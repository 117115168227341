import React, { useState } from 'react';

import { Select } from 'antd';

import { User } from '../models/User';
import { Zond } from '../models/Zond';

const { Option } = Select;

interface Props {
    user: User;
    zonds: Zond[];
    handleChangeZond: (s: string) => void;
}

export default function DeviceSelect(props: Props) {
    const [search, setSearch] = useState('');

    return (
        <Select
            showSearch={props.user.is_admin}
            onChange={(value) => value && props.handleChangeZond(value.toString())}
            placeholder="Выберите устройство"
            style={{ width: 348, maxWidth: '100%' }}
            size="large"
            onSearch={(value) => setSearch(value)}
        >
            {props.zonds.map((zond) => {
                if (zond.number.includes(search)) {
                    return (
                        <Option value={zond.number_in_db} key={zond.id}>
                            {`${zond.zond_type.displayed_name} №${zond.number} ${
                                props.user.is_admin ? `(${zond.zond_status.status})` : ''
                            }`}
                        </Option>
                    );
                }
            })}
        </Select>
    );
}
