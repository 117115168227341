import React from 'react';

import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Col, Typography } from 'antd';

import { materialColorPalette } from '../../../helpers/constants';
import { DigitalHelper } from '../../../models/DigitalHelper';

const { Title } = Typography;

interface Props {
    metrics: DigitalHelper;
}

export const GrowingSeasonHeatSupplyGraph = (props: Props) => {
    const metrics = props.metrics;
    const gshs = metrics.GrowingSeasonHeatSupply;

    // @ts-ignore
    let data = [];
    if (gshs.T0DailyData) {
        for (let i = 0; i < gshs.T0DailyData.length; i++) {
            data.push({
                '0 °C': Math.round(gshs.T0DailyData[i]),
                '5 °C': Math.round(gshs.T5DailyData[i]),
                '10 °C': Math.round(gshs.T10DailyData[i]),
            });
        }
    }

    return (
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <Title level={5}>Накопление полезных температур</Title>
            <ResponsiveContainer width="100%" height={130}>
                <LineChart
                    data={data}
                    margin={{
                        right: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="0 °C" stroke={materialColorPalette[0]} />
                    <Line type="monotone" dataKey="5 °C" stroke={materialColorPalette[2]} />
                    <Line type="monotone" dataKey="10 °C" stroke={materialColorPalette[4]} />
                </LineChart>
            </ResponsiveContainer>
        </Col>
    );
};
