import React from 'react';

import { Col, Typography } from 'antd';

import { DigitalHelper } from '../../../models/DigitalHelper';

import moment from 'moment';

const { Title } = Typography;

interface Props {
    metrics: DigitalHelper;
}

export const SoilWarmingDatePanel = (props: Props) => {
    const metrics = props.metrics;

    return (
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            {/*<OverlayTrigger overlay={<Tooltip id="tooltip2">Актуально для весны</Tooltip>}>*/}
            <Title level={5}>Дата прогревания почвы (10 см)</Title>
            {/*</OverlayTrigger>*/}
            <ul>
                <li>
                    до 5°C:
                    <b> {metrics.SoilWarmingDate?.T5 ? moment(metrics.SoilWarmingDate.T5 * 1000).format('LL') : '-'}</b>
                </li>
                <li>
                    до 10°C:
                    <b>
                        {' '}
                        {metrics.SoilWarmingDate?.T10 ? moment(metrics.SoilWarmingDate.T10 * 1000).format('LL') : '-'}
                    </b>
                </li>
            </ul>
        </Col>
    );
};
