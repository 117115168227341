import React, { useState } from 'react';

import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { getDataMax, getDataMin, preprocessDataForLineChart } from '../../../helpers/RechartsPreprocess';
import { materialColorPalette } from '../../../helpers/constants';
import { Graph } from '../../../models/Graph';

interface Props {
    graph: Graph;
}

export const ResponsiveLineChart = (props: Props) => {
    const graph = props.graph;

    let o: { [key: string]: number } = {};
    for (let l of graph.Legends) {
        o[l] = 1;
    }

    let [opacity, setOpacity] = useState(o);

    const handleMouseEnter = (e: any) => {
        let dataKey: string = e['dataKey'];
        let newOpacity: { [key: string]: number } = {};

        for (let l of graph.Legends) {
            newOpacity[l] = 0.1;
        }

        newOpacity[dataKey] = 1;
        setOpacity(newOpacity);
    };

    const handleMouseLeave = () => {
        let newOpacity: { [key: string]: number } = {};

        for (let l of graph.Legends) {
            newOpacity[l] = 1;
        }

        setOpacity(newOpacity);
    };

    return (
        <ResponsiveContainer width="98%" height={400}>
            <LineChart data={preprocessDataForLineChart(graph['Values'], graph['Legends'])}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={graph['Legends'][0]} />
                <YAxis
                    type="number"
                    allowDataOverflow={false}
                    domain={[getDataMin(graph['Values']), getDataMax(graph['Values'])]}
                />
                <Tooltip filterNull={true} />
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                {graph['Legends'].map((legend, i) => {
                    if (i >= 1) {
                        if (legend.startsWith('(прогноз)')) {
                            const offset = Math.floor(graph.Legends.length / 2);
                            return (
                                <Line
                                    connectNulls
                                    key={i}
                                    type="monotone"
                                    dataKey={legend}
                                    stroke={materialColorPalette[i - offset]}
                                    strokeWidth={2}
                                    strokeDasharray="5 5"
                                />
                            );
                        } else
                            return (
                                <Line
                                    connectNulls
                                    key={i}
                                    type="monotone"
                                    dataKey={legend}
                                    stroke={materialColorPalette[i]}
                                    strokeOpacity={opacity[legend]}
                                    strokeWidth={2}
                                />
                            );
                    } else return <span key={i} />;
                })}
            </LineChart>
        </ResponsiveContainer>
    );
};
