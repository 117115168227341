export class User {
    id: number = 0;
    username: string = '';
    surname?: string = '';
    name?: string = '';
    patronymic?: string = '';
    profession?: string = '';
    email: string = '';
    phone_number?: string = '';
    is_admin: boolean = false;
}
