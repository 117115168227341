import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Col, Form, Input, message, Modal, Row } from 'antd';

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import logo from '../../assets/logo.svg';
import history from '../../history';
import { api } from '../../http/API';
import { saveCompany } from '../../storage/CompanyStorage';
import { saveUser } from '../../storage/UserStorage';
import { saveZonds } from '../../storage/ZondsStorage';

const LoginPage = () => {
    const dispatch = useDispatch();

    let [showModal, setShowModal] = useState(false);

    const handleSubmit = async (values: any) => {
        console.log(values);

        api.LoginUser(values.username.trim(), values.password.trim())
            .then((resp: any) => {
                localStorage.setItem('token', resp['token']);
                localStorage.setItem('verbose', resp['is_admin'] ? '1' : '0');

                api.GetUser()
                    .then((res) => dispatch(saveUser(res)))
                    .catch((_) => message.error('Не удалось загрузить пользователя, повторите попытку позже'));

                api.GetCompany()
                    .then((res) => dispatch(saveCompany(res)))
                    .catch((_) => message.error('Не удалось загрузить данные о компании, повторите попытку позже'));

                api.GetZonds()
                    .then((res) => dispatch(saveZonds(res)))
                    .catch((_) => message.error('Не удалось загрузить метеоустройства, повторите попытку позже'));

                history.push('/');
                message.success('Вход выполнен успешно');
            })
            .catch((error) => {
                if (!error.response) {
                    message.error('Сервис временно недоступен');
                } else {
                    message.error('Неверные логин или пароль');
                }
            });
    };

    return (
        <>
            <Row justify="center">
                <Col>
                    <div style={{ width: 220, margin: '50px auto', textAlign: 'center' }}>
                        <img style={{ float: 'left', marginRight: 12 }} src={logo} alt={'logo'} height={80} />
                        <h1 className="text" style={{ margin: 0 }}>
                            Агропогода
                        </h1>
                        <h3 className="text" style={{ margin: 0 }}>
                            Личный кабинет
                        </h3>
                    </div>
                    <div style={{ clear: 'left' }} />

                    <Form onFinish={handleSubmit} autoComplete="off">
                        <Form.Item name="username" rules={[{ required: true, message: 'Введите логин' }]}>
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Логин" />
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: 0 }}
                            name="password"
                            rules={[{ required: true, message: 'Введите пароль' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Пароль"
                            />
                        </Form.Item>

                        <Form.Item style={{ margin: 8 }} wrapperCol={{ offset: 12, span: 8 }}>
                            <Button type="link" onClick={() => setShowModal(true)}>
                                Забыли пароль?
                            </Button>
                        </Form.Item>

                        <Form.Item style={{ margin: 0 }} wrapperCol={{ offset: 8, span: 16 }}>
                            <Button size="large" type="primary" htmlType="submit">
                                Войти
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Modal
                    cancelButtonProps={{ style: { display: 'none' } }}
                    title="Забыли пароль?"
                    onCancel={() => setShowModal(false)}
                    visible={showModal}
                    onOk={() => setShowModal(false)}
                >
                    <p>
                        Заявки с просьбой восстановления пароля принимаются на почту <u>info@umium.com</u>
                    </p>
                    <p>Просьба указать в письме ваши ФИО и логин, с которым вы заходили в систему.</p>
                </Modal>
            </Row>
        </>
    );
};

export default LoginPage;
