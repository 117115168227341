import { User } from './User';
import { Zond } from './Zond';

export class Company {
    id: number = 0;
    created_at: string = '';
    name: string = '';
    phone?: string = undefined;
    address?: string = undefined;
    description?: string = undefined;
    location_region_id?: number;
    users: User[] = [];
    zonds: Zond[] = [];
}
