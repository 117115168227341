import moment from 'moment';

function convertToCSV(objArray: string): string {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let elem of array) {
        let line = '';

        for (let index in elem) {
            if (line !== '') line += ',';

            if (elem[index] !== null) {
                line += elem[index].toString();
            }
        }

        str += line + '\r\n';
    }

    return str;
}

export function exportCSVFile(headers: any[], items: any[], fileTitle: string) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    let jsonObject = JSON.stringify(items);
    let csv = convertToCSV(jsonObject);
    let exportedFilename = fileTitle + '.csv' || 'export.csv';

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    // @ts-ignore
    if (navigator.msSaveBlob) {
        // IE 10+
        // @ts-ignore
        navigator.msSaveBlob(blob, exportedFilename);
    } else {
        let link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
