import React from 'react';

import { Col, Typography } from 'antd';

import { DigitalHelper } from '../../../models/DigitalHelper';

const { Title } = Typography;

interface Props {
    metrics: DigitalHelper;
}

export const GrowingSeasonDurationPanel = (props: Props) => {
    const metrics = props.metrics;

    return (
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <Title level={5}>Продолжительность вегетационного периода</Title>
            <ul>
                <li>
                    С температурой выше 5°C:
                    <b> {metrics['GrowingSeasonDuration']['T5']} дней</b>
                </li>
                <li>
                    С температурой выше 10°C:
                    <b> {metrics['GrowingSeasonDuration']['T10']} дней</b>
                </li>
            </ul>
        </Col>
    );
};
