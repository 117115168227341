type StringNumberMap = Record<string, number>;

export const monitoringTimePeriods: StringNumberMap = {
    'Последние 6 часов': 6,
    'Последние 24 часа': 24,
    'Последние 3 дня': 24 * 3,
    'Последняя неделя': 24 * 7,
    'Последние две недели': 24 * 7 * 2,
    'Последний месяц': 24 * 7 * 4,
    'Последние три месяца': 24 * 7 * 4 * 3,
    'Последние полгода': 24 * 7 * 4 * 6,
    'Последний год': 24 * 7 * 4 * 12,
    'Последние два года': 24 * 7 * 4 * 12 * 2,
};

export const monitoringTimeDays: StringNumberMap = {
    'Последние 24 часа': 1,
    'Последние 3 дня': 3,
    'Последняя неделя': 7,
    'Последний месяц': 30,
};

export const materialColorPalette = [
    '#e53935',
    '#d81b60',
    '#8e24aa',
    '#5e35b1',
    '#3949ab',
    '#1e88e5',
    '#00acc1',
    '#00897b',
    '#43a047',
    '#7cb342',
    '#c0ca33',
    '#fdd835',
    '#ffb300',
];
