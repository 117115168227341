import React from 'react';

import { Col, Typography } from 'antd';

import { DigitalHelper } from '../../../models/DigitalHelper';

import moment from 'moment';

const { Title } = Typography;

interface Props {
    metrics: DigitalHelper;
}

export const TemperatureStatsPanel = (props: Props) => {
    const metrics = props.metrics;

    return (
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <Title level={5}>Температура воздуха</Title>
            <ul>
                <li>
                    Максимум:
                    <b>
                        {' '}
                        {metrics['PeakTemperature']['MaxDate']
                            ? `${metrics['PeakTemperature']['MaxT']}°C (${moment(
                                  metrics['PeakTemperature']['MaxDate'] * 1000,
                              ).format('LL')})`
                            : '-'}
                    </b>
                </li>
                <li>
                    Минимум:
                    <b>
                        {' '}
                        {metrics['PeakTemperature']['MinDate']
                            ? `${metrics['PeakTemperature']['MinT']}°C: (${moment(
                                  metrics['PeakTemperature']['MinDate'] * 1000,
                              ).format('LL')})`
                            : '-'}
                    </b>
                </li>
                <li>
                    Средняя за период:
                    <b>
                        {' '}
                        {metrics['TemperatureStats']['Average']
                            ? `${metrics['TemperatureStats']['Average'].toFixed(2)} °C`
                            : '-'}
                    </b>
                </li>
                <li>
                    Кол-во измерений:
                    <b> {metrics['TemperatureStats']['Count'] ? `${metrics['TemperatureStats']['Count']}` : '-'}</b>
                </li>
            </ul>
        </Col>
    );
};
