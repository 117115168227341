import { createSlice } from '@reduxjs/toolkit';

import { Zond } from '../models/Zond';

export const ZondsStorage = createSlice({
    name: 'zonds',
    initialState: {
        zonds: [],
    },
    reducers: {
        saveZonds: (state, action) => {
            state.zonds = action.payload;
        },
    },
});

export const { saveZonds } = ZondsStorage.actions;

interface RootState {
    zonds: Zond[];
}

export const selectZonds = (state: RootState) => state.zonds;

export default ZondsStorage.reducer;
