import React from 'react';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

interface Props {
    size: any;
    onChange: (dates: any) => void;
}
export default function TimeRange(props: Props) {
    return (
        <RangePicker
            size={props.size}
            onChange={props.onChange}
            style={{ width: '50%' }}
            placeholder={['Начало периода', 'Конец периода']}
        />
    );
}
